import {
  Icon,
  IconArrowBackUp,
  IconArrowRight,
  IconArticle,
  IconBan,
  IconBrandTelegram,
  IconCalendarTime,
  IconCheck,
  IconChevronRight,
  IconEdit,
  IconEye,
  IconFileDislike,
  IconFileImport,
  IconFileInvoice,
  IconFileUpload,
  IconFlag,
  IconHeartHandshake,
  IconLicense,
  IconMessage2,
  IconMessage2Question,
  IconPencilPlus,
  IconPlus,
  IconReportMoney,
  IconThumbDown,
  IconThumbUp,
  IconTournament,
  IconTransfer,
  IconTrophy,
  IconUserMinus,
  IconUserPlus,
  IconWriting,
} from '@tabler/icons-react';
import { EventComplete, EventDetailUnion } from 'bff';
import { EventDetailObjects, EventType } from 'database';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage, Badge, cn } from 'ui';
import { FileList, FileListItem, FileListLabel } from './File';
import { useTranslations } from 'i11n';
import { currencyFormat } from 'utils';
import { IconReport } from '@tabler/icons-react';
import { IconX, IconAlertCircle } from '@tabler/icons-react';
import { IconQuestionMark } from '@tabler/icons-react';
import { IconSend } from '@tabler/icons-react';
import { format, isValid } from 'date-fns';
import { useSharedAuth } from '..';

const iconsByEvents: {
  [K in EventType]: Icon;
} = {
  complaint_rejected: IconThumbDown,
  complaint_approved: IconThumbUp,
  complaint_authorized: IconCheck,
  external_complaint_selected: IconCheck,
  claim_updated: IconEdit,
  claim_estimates_updated: IconReportMoney,
  claim_ownership_assigned: IconTransfer,
  claim_ownership_transfer_rejected: IconBan,
  claim_ownership_transfer_accepted: IconCheck,
  claim_ownership_transfer_requested: IconBrandTelegram,
  contract_created: IconWriting,
  order_created: IconWriting,
  rfp_created: IconTournament,

  contract_invoice_uploaded: IconFileInvoice,
  contract_updates_requested: IconQuestionMark,
  contract_updates_published: IconArticle,

  claim_documentation_requested: IconQuestionMark,

  order_invoice_uploaded: IconFileInvoice,
  order_updates_requested: IconQuestionMark,
  order_updates_published: IconArticle,

  contract_report_rejected: IconX,
  contract_report_created: IconReport,
  contract_report_accepted: IconCheck,

  order_report_rejected: IconX,
  order_report_created: IconReport,
  order_report_accepted: IconCheck,

  appraisal_invoice_uploaded: IconFileInvoice,
  appraisal_updates_requested: IconQuestionMark,
  appraisal_updates_published: IconArticle,
  appraisal_report_rejected: IconX,
  appraisal_report_created: IconReport,
  appraisal_report_accepted: IconCheck,

  external_complaint_created: IconPencilPlus,

  pending_complaint_created: IconPlus,

  offer_expired: IconCalendarTime,
  offer_rejected: IconFileDislike,
  offer_accepted: IconHeartHandshake,

  offer_agreement_expired: IconCalendarTime,
  offer_agreement_cancelled: IconFileDislike,
  offer_agreement_rejected: IconFileDislike,
  offer_agreement_completed: IconLicense,

  sinister_complaint_chat_message_send: IconMessage2,
  sinister_claim_chat_message_send: IconMessage2,
  fraud_news_requested: IconMessage2Question,
  fraud_news_answered: IconArticle,
  claim_form_step_completed: IconFlag,
  claim_form_step_view: IconEye,

  claim_derived: IconArrowRight,
  claim_responsibility_returned: IconArrowBackUp,

  claim_responsible_unassigned: IconUserMinus,
  claim_responsible_assigned: IconUserPlus,

  claim_collaborator_unassigned: IconUserMinus,
  claim_collaborator_assigned: IconUserPlus,

  complaint_collaborator_unassigned: IconUserMinus,
  complaint_collaborator_assigned: IconUserPlus,

  claim_created: IconLicense,
  complaint_created: IconLicense,
  file_requested: IconFileImport,
  file_request_uploaded: IconFileUpload,
  upload_files: IconFileUpload,
  claim_status_updated: IconFileUpload,
  complaint_status_updated: IconFileUpload,
  complaint_sinister_cause_updated: IconFileUpload,
  rfp_bid_submitted: IconSend,
  rfp_bid_winner_selected: IconTrophy,
  rfp_expired: IconX,

  alert_sla_objective_progress: IconAlertCircle,
  alert_sla_total_progress: IconAlertCircle,
};

interface ActivityContext {
  eventsCount: number;
  event: EventComplete;
  index: number;
}

const ActivityContext = React.createContext<ActivityContext | undefined>(
  undefined,
);

const useActivityContext = () => React.useContext(ActivityContext)!;

const EventDetailList = ({ children }: { children: React.ReactNode }) => {
  return <div className='gap-2 flex flex-row flex-wrap'>{children}</div>;
};

const MessageAuthor = ({ children }: { children: React.ReactNode }) => {
  return <p className='text-muted-foreground text-xs italic'>"{children}"</p>;
};

const EventDetailContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('w-full mt-3 space-y-2', className)}>{children}</div>
  );
};

function isIsoDate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}

const parseSuperType = (value: any, fallback: () => string) => {
  if (value && isIsoDate(value)) return format(new Date(value), 'dd/MM/yyyy');

  if (typeof value === 'boolean') return value ? 'Si' : 'No';

  return fallback();
};

const EventDetail = () => {
  const { event } = useActivityContext();

  const t = useTranslations('claim.steps');

  const rootT = useTranslations();

  switch (event.type) {
    case 'alert_sla_objective_progress':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>SLA: {event.metadata.sla_name}</Badge>
            <Badge variant='outline'>
              {rootT(
                `sla.types.${event.metadata.sla_objective_reference_type}`,
              )}
              : {event.metadata.sla_objective_reference_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'alert_sla_total_progress':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>SLA: {event.metadata.sla_name}</Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'complaint_estimates_updated':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Cobertura: {event.metadata.affected_coverage_name}
            </Badge>
            {event.metadata.operands.map((operand) => (
              <Badge variant='outline'>
                {operand.name}: {operand.value}
              </Badge>
            ))}
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_rejected':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>

            <Badge variant='outline'>Motivo: {event.metadata.reason}</Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_approved':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_authorized':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'external_complaint_created':
      return (
        <EventDetailContainer>
          <EventDetailList>
            <Badge variant='outline'>
              Denuncia: {event.metadata.external_complaint_id}
            </Badge>

            <Badge variant='outline'>
              Póliza: {event.metadata.external_policy_number}
            </Badge>

            <Badge variant='outline'>
              Coberturas: {event.metadata.coverages.join(', ')}
            </Badge>
            <Badge variant='outline'>
              Causa del siniestro: {event.metadata.sinister_cause_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'rfp_expired':
      return (
        <EventDetailContainer>
          <Badge variant='outline'>{event.metadata.rfp_name}</Badge>
        </EventDetailContainer>
      );

    case 'contract_invoice_uploaded':
    case 'appraisal_invoice_uploaded':
      return (
        <EventDetailContainer>
          <Badge variant='outline'>
            {event.metadata.invoice_original_name}
          </Badge>
        </EventDetailContainer>
      );

    case 'order_updates_published':
    case 'order_updates_requested':
    case 'contract_updates_published':
    case 'contract_updates_requested':
    case 'appraisal_updates_published':
    case 'appraisal_updates_requested':
      return (
        <EventDetailContainer>
          <MessageAuthor>{event.metadata.message}</MessageAuthor>
        </EventDetailContainer>
      );

    case 'contract_report_rejected':
    case 'contract_report_accepted':
    case 'contract_report_created':
    case 'appraisal_report_rejected':
    case 'appraisal_report_accepted':
    case 'appraisal_report_created':
      return (
        <EventDetailContainer>
          {event.type === 'appraisal_report_rejected' && (
            <MessageAuthor>{event.metadata.reason}</MessageAuthor>
          )}
          <EventDetailList>
            <Badge variant='outline'>Version {event.metadata.version}</Badge>
            <Badge variant='outline'>
              Informe {event.metadata.report_original_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'claim_estimates_updated':
      return (
        <EventDetailContainer>
          {event.metadata.reason && (
            <span className='text-xs italic'>"{event.metadata.reason}"</span>
          )}

          <EventDetailList>
            <Badge variant='outline'>
              {rootT(
                `claim.estimates.${event.metadata.claim_estimates_property}`,
              )}
            </Badge>
          </EventDetailList>
          <div className='flex flex-row items-center'>
            {event.metadata.old_value && (
              <>
                <Badge variant='outline'>{event.metadata.old_value}</Badge>
                <IconArrowRight className='w-4 h-4 text-muted-foreground mx-2' />
              </>
            )}
            <Badge variant='outline'>{event.metadata.new_value}</Badge>
          </div>
        </EventDetailContainer>
      );

    case 'offer_expired':
    case 'offer_rejected':
    case 'offer_accepted':
      return (
        <EventDetailContainer className='space-y-3'>
          {event.type === 'offer_rejected' && (
            <MessageAuthor>{event.metadata.reason}</MessageAuthor>
          )}
          <EventDetailList>
            <Badge variant={'outline'}>{event.metadata.offer_name}</Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'offer_agreement_expired':
    case 'offer_agreement_rejected':
    case 'offer_agreement_completed':
    case 'offer_agreement_cancelled':
      return (
        <EventDetailContainer className='space-y-3'>
          {event.type === 'offer_agreement_cancelled' && (
            <MessageAuthor>{event.metadata.cancelled_reason}</MessageAuthor>
          )}
          {event.type === 'offer_agreement_rejected' && (
            <MessageAuthor>{event.metadata.rejected_reason}</MessageAuthor>
          )}
          <EventDetailList>
            <Badge variant={'outline'}>
              {event.metadata.offer_agreement_name}
            </Badge>
          </EventDetailList>
        </EventDetailContainer>
      );

    case 'file_request_uploaded':
      return (
        <EventDetailContainer>
          <FileList className='w-min'>
            {event.details.map((detail) => {
              if (detail.object_type !== 'file') return;

              return (
                <a
                  className='hover:underline'
                  target='_blank'
                  href={detail.file.url}
                >
                  <FileListItem className='h-6 mr-2'>
                    <FileListLabel
                      mimetype={detail.file.mimetype!}
                      className='w-72 [&>svg]:w-4 [&>svg]:h-4'
                    >
                      {detail.file.stored_name}
                    </FileListLabel>
                  </FileListItem>
                </a>
              );
            })}
          </FileList>
        </EventDetailContainer>
      );

    case 'file_requested': {
      const [fileRequest] = event.details;

      console.log('event.details', event.details, 'event', event);

      if (fileRequest.object_type !== 'file_request') return;

      return (
        <EventDetailContainer>
          <EventDetailList>
            {fileRequest.file_request.placeholders.map((placeholder) => {
              return (
                <Badge variant={'outline'} key={placeholder.id}>
                  {placeholder.tag.name}
                </Badge>
              );
            })}
          </EventDetailList>
        </EventDetailContainer>
      );
    }

    case 'claim_form_step_view':
      return (
        <EventDetailContainer>
          <Badge variant={'outline'}>{t(event.metadata.step)}</Badge>
        </EventDetailContainer>
      );

    case 'complaint_updated':
    case 'claim_updated': {
      const relationType = event.type.replace('_updated', '');

      return (
        <EventDetailContainer>
          {event.metadata.diff
            .filter((diff) => {
              return [diff.oldValue, diff.value].every((val) => {
                if (val === null) return true;

                return typeof val !== 'object' && !Array.isArray(val);
              });
            })
            .map((diff, index) => {
              const segments = diff.path.filter(
                (path): path is string => typeof path === 'string',
              );

              const label = segments
                .map((segment, index) => {
                  const prevSegments = segments.slice(0, index);

                  return rootT(
                    `${relationType}.attributes${
                      prevSegments.length ? `.${prevSegments.join('.')}` : ''
                    }.${segment}.label`,
                  );
                })
                .join(' / ');

              const path = `${relationType}.attributes.${diff.path
                .filter((path): path is string => typeof path === 'string')
                .join('.')}`;

              return (
                <div className='flex flex-row items-center space-x-2'>
                  <Badge
                    className='flex flex-col items-start'
                    key={index}
                    variant={'outline'}
                  >
                    <span className='text-xs text-muted-foreground'>
                      {label}
                    </span>
                    <div className='flex flex-row items-center space-x-2'>
                      <span>
                        {parseSuperType(diff.oldValue, () =>
                          rootT(
                            `${path}.value.${diff.oldValue}`,
                            {},
                            diff.oldValue || '',
                          ),
                        )}
                      </span>
                      <IconChevronRight className='w-4 h-4' />
                      <span>
                        {parseSuperType(diff.value, () =>
                          rootT(
                            `${path}.value.${diff.value}`,
                            {},
                            diff.value || '',
                          ),
                        )}
                      </span>
                    </div>
                  </Badge>
                </div>
              );
            })}
        </EventDetailContainer>
      );
    }

    case 'sinister_complaint_chat_message_send':
    case 'sinister_claim_chat_message_send':
      return (
        <EventDetailContainer>
          <p className='italic'>"{event.metadata.message}"</p>
        </EventDetailContainer>
      );

    case 'claim_form_step_completed':
      return (
        <EventDetailContainer>
          <Badge variant={'outline'}>{t(event.metadata.step)}</Badge>
        </EventDetailContainer>
      );

    default:
      return null;
  }
};

const Content = ({ children }: { children: React.ReactNode }) => {
  const { index } = useActivityContext();

  return (
    <div
      className={cn('ml-6 text-sm flex flex-col', {
        'mt-5': index !== 0,
      })}
    >
      {children}
    </div>
  );
};

const TriggeredByAvatar = ({
  triggeredBy,
}: {
  triggeredBy: EventComplete['triggered_by'];
}) => {
  console.log('triggeredBy', triggeredBy);

  return (
    <Avatar size='xs'>
      <AvatarImage src={triggeredBy.profile_photo!} />
      <AvatarFallback className='text-xs'>
        {`${triggeredBy.first_name.charAt(0)}${triggeredBy?.last_name?.charAt(
          0,
        )}`}
      </AvatarFallback>
    </Avatar>
  );
};

const Marker = () => {
  const { index, event } = useActivityContext();

  const Icon = iconsByEvents[event.type];

  return (
    <div
      className={cn('absolute -left-3', {
        'top-5': index !== 0,
      })}
    >
      <div className='rounded-full p-1 bg-card shadow'>
        {Icon && <Icon className='w-4 h-4 text-muted-foreground' />}
      </div>
    </div>
  );
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-row items-start space-x-2'>{children}</div>;
};

const findEventDetailObject = <T extends EventDetailObjects>(
  details: EventDetailUnion[],
  object: T,
) =>
  details.find(
    (detail): detail is Extract<EventDetailUnion, { object_type: T }> =>
      detail.object_type === object,
  ) as Extract<EventDetailUnion, { object_type: T }>;

const EventAction = () => {
  const { event } = useActivityContext();

  const t = useTranslations();

  switch (event.type) {
    case 'complaint_updated':
      return <>actualizó datos de la denuncia</>;

    case 'alert_sla_total_progress':
      return (
        <>
          notifica que el SLA {event.metadata.sla_name} esta al{' '}
          {event.metadata.sla_total_progress}% de su progreso
        </>
      );

    case 'alert_sla_objective_progress':
      return (
        <>
          notifica que el{' '}
          {t(`sla.types.${event.metadata.sla_objective_reference_type}`)}{' '}
          {event.metadata.sla_objective_reference_name} del SLA{' '}
          {event.metadata.sla_name} esta al{' '}
          {event.metadata.sla_objective_progress}% de su progreso
        </>
      );

    case 'complaint_responsible_assigned': {
      return (
        <>
          asignó a <strong>{event.metadata.responsible_user_email}</strong> como
          responsable
        </>
      );
    }

    case 'complaint_collaborator_assigned': {
      return (
        <>
          agrego a <strong>{event.metadata.collaborator_user_email}</strong>{' '}
          como colaborador
        </>
      );
    }

    case 'complaint_collaborator_unassigned': {
      return (
        <>
          removió a <strong>{event.metadata.collaborator_user_email}</strong>{' '}
          como colaborador
        </>
      );
    }

    case 'complaint_collaborator_unassigned':
      return <>removió un colaborador</>;

    case 'complaint_collaborator_assigned':
      return <>agrego un colaborador</>;

    case 'complaint_responsible_assigned':
      return <>agrego un responsable</>;

    case 'complaint_estimates_updated':
      return <>actualizó las estimaciones</>;

    case 'external_complaint_rejected':
      return <>rechazo la denuncia en el sistema tercero</>;

    case 'complaint_rejected':
      return <>rechazo la denuncia</>;

    case 'external_complaint_approved':
      return <>aprobó la denuncia en el sistema tercero</>;

    case 'complaint_approved':
      return <>aprobó la denuncia</>;

    case 'external_complaint_authorized':
      return <>autorizó la denuncia en el sistema tercero</>;

    case 'complaint_authorized':
      return <>autorizó la denuncia</>;

    case 'external_complaint_created':
      return <>creó la denuncia en el sistema tercero</>;

    case 'rfp_expired':
      return <>expiró la licitación</>;

    case 'claim_updated':
      return <>actualizó datos del reclamo</>;

    case 'claim_estimates_updated':
      return <>actualizó un dato de la estimación</>;

    case 'pending_file_request_upload':
      return (
        <>notificó al reclamante usuario sobre la documentación pendiente</>
      );

    case 'pending_offer_response_internal':
      return (
        <>
          notificó al tramitador responsable sobre el ofrecimiento pendiente sin
          responder
        </>
      );

    case 'pending_offer_response':
      return (
        <>
          notificó al reclamante usuario sobre el ofrecimiento pendiente sin
          responder
        </>
      );

    case 'pending_offer_agreement_response':
      return (
        <>notificó al reclamante usuario sobre la conformidad sin completar</>
      );

    case 'pending_offer_agreement_response_internal':
      return (
        <>
          notificó al tramitador responsable sobre la conformidad sin completar
        </>
      );

    case 'claim_documentation_requested':
      return <>solicitó documentación</>;

    case 'claim_ownership_assigned':
      return (
        <>
          asignó como reclamante usuario a:{' '}
          <strong>{event.metadata.destination_user_name}</strong>
        </>
      );

    case 'claim_ownership_transfer_rejected':
      return (
        <>rechazo la solicitud de transferencia de propiedad del reclamo</>
      );

    case 'claim_ownership_transfer_accepted':
      return <>acepto la solicitud de transferencia de propiedad del reclamo</>;

    case 'claim_ownership_transfer_requested':
      return (
        <>
          envió una solicitud de transferencia de propiedad del reclamo al
          usuario:
          <strong>"{event.metadata.destination_user_email}"</strong>
        </>
      );

    case 'contract_report_created':
      return (
        <>
          agregó un reporte en el contrato:{' '}
          <strong>"{event.metadata.contract_name}"</strong>
        </>
      );

    case 'order_report_created':
      return (
        <>
          agregó un reporte en la orden:{' '}
          <strong>"{event.metadata.order_name}"</strong>
        </>
      );

    case 'contract_invoice_uploaded':
      return (
        <>
          subió una factura en el contrato:{' '}
          <strong>"{event.metadata.contract_name}"</strong>
        </>
      );

    case 'order_created':
      return (
        <>
          creó la orden: <strong>"{event.metadata.order_name}"</strong>
        </>
      );

    case 'contract_created':
      return (
        <>
          creó el contrato: <strong>"{event.metadata.contract_name}"</strong>
        </>
      );

    case 'rfp_bid_submitted':
      return <>envió su oferta en la licitación</>;

    case 'rfp_bid_winner_selected':
      return (
        <>
          seleccionó al proveedor ganador:{' '}
          <strong>"{event.metadata.vendor_contact_name}"</strong>
        </>
      );

    case 'rfp_created':
      return (
        <>
          creó la licitación: <strong>{event.metadata.rfp_name}</strong>
        </>
      );

    case 'order_updates_requested':
      return <>solicitó novedades de la orden</>;

    case 'contract_updates_requested':
      return <>solicitó novedades del contrato</>;

    case 'order_updates_requested':
      return <>solicitó novedades de la orden</>;

    case 'contract_updates_published':
      return <>publicó novedades del contrato</>;

    case 'order_updates_published':
      return <>publicó novedades de la orden</>;

    case 'contract_report_rejected':
      return <>rechazó un reporte de contrato</>;

    case 'order_report_rejected':
      return <>rechazó un reporte de orden</>;

    case 'contract_report_accepted':
      return <>aceptó un reporte de contrato</>;

    case 'order_report_accepted':
      return <>aceptó un reporte de orden</>;

    case 'appraisal_invoice_uploaded':
      return <>subió una factura de la evaluación</>;

    case 'appraisal_updates_requested':
      return <>solicito novedades de la evaluación</>;

    case 'appraisal_updates_published':
      return <>publico novedades sobre la evaluación</>;

    case 'appraisal_report_rejected':
      return <>rechazo un informe de evaluación</>;

    case 'appraisal_report_created':
      return <>agrego un informe de evaluación</>;

    case 'appraisal_report_accepted':
      return <>acepto un informe de evaluación</>;

    case 'offer_agreement_completed':
      return <>una conformidad fue completada</>;

    case 'offer_agreement_expired':
      return <>una conformidad expiró</>;

    case 'offer_agreement_rejected':
      return <>una conformidad fue rechazada por el tercero</>;

    case 'offer_agreement_cancelled':
      return <>una conformidad fue cancelada por la empresa</>;

    case 'offer_expired':
      return <>expiro un ofrecimiento</>;

    case 'offer_rejected':
      return <>rechazó un ofrecimiento:</>;

    case 'offer_accepted':
      return <>aceptó un ofrecimiento</>;

    case 'fraud_news_answered':
      return <>agregó novedades a la investigación</>;

    case 'fraud_news_requested':
      return <>solicito novedades de la investigación</>;

    case 'claim_form_step_completed':
      return <>completo un paso del formulario</>;

    case 'claim_form_step_view':
      return <>visito un paso del formulario</>;

    case 'sinister_complaint_chat_message_send':
    case 'sinister_claim_chat_message_send':
      return <>envió un mensaje</>;

    case 'file_request_uploaded':
      return <>subió la siguiente documentación:</>;

    case 'complaint_created':
      return <>creó la denuncia</>;

    case 'claim_created':
      return <>creó el reclamo</>;

    case 'file_requested':
      return <>solicitó la siguiente documentación:</>;

    case 'claim_responsibility_returned': {
      return (
        <>
          retornó el reclamo a{' '}
          <strong>
            {event.metadata.responsible_user_first_name}{' '}
            {event.metadata.responsible_user_last_name} (
            {event.metadata.responsible_user_email}){' '}
          </strong>
          y dejo el siguiente motivo:
          <p className='text-sm text-muted-foreground italic mt-2'>
            "{event.metadata.reason}"
          </p>
        </>
      );
    }

    case 'pending_complaint_created':
      return (
        <>
          creó una denuncia de oficio vinculando el reclamo{' '}
          <strong>#{event.metadata.claim_id}</strong>
        </>
      );

    case 'external_complaint_selected':
      return (
        <>
          seleccionó la denuncia{' '}
          <strong>{event.metadata.external_complaint_external_id}</strong> como
          principal del siniestro
        </>
      );

    case 'claim_derived': {
      return (
        <>
          derivó el reclamo a{' '}
          <strong>
            {event.metadata.responsible_user_first_name}{' '}
            {event.metadata.responsible_user_last_name} (
            {event.metadata.responsible_user_email})
          </strong>{' '}
          y dejo el siguiente motivo:
          <p className='text-sm text-muted-foreground italic mt-2'>
            "{event.metadata.reason}"
          </p>
        </>
      );
    }

    case 'claim_responsible_assigned': {
      const detail = findEventDetailObject(event.details, 'user');

      if (!detail) return;

      return (
        <>
          asignó a{' '}
          <strong>
            {detail.user.first_name} {detail.user.last_name}
          </strong>{' '}
          como responsable
        </>
      );
    }

    case 'claim_collaborator_assigned': {
      const { user } = findEventDetailObject(event.details, 'user');

      if (!user) {
        return;
      }

      return (
        <>
          agrego a{' '}
          <strong>
            {user.first_name} {user.last_name}
          </strong>{' '}
          como colaborador
        </>
      );
    }

    case 'claim_collaborator_unassigned': {
      const { user } = findEventDetailObject(event.details, 'user');

      if (!user) {
        return;
      }

      return (
        <>
          removió a{' '}
          <strong>
            {user.first_name} {user.last_name}
          </strong>{' '}
          como colaborador
        </>
      );
    }

    case 'complaint_sinister_cause_updated': {
      return (
        <>
          actualizo la causa del siniestro de{' '}
          <strong>{event.metadata.previous_sinister_cause_name || ''}</strong> a{' '}
          <strong>{event.metadata.new_sinister_cause_name}</strong>{' '}
        </>
      );
    }

    case 'complaint_status_updated':
    case 'claim_status_updated': {
      return (
        <>
          actualizo el estado de{' '}
          <strong>{event.metadata.previous_status_name}</strong> a{' '}
          <strong>{event.metadata.new_status_name}</strong>{' '}
        </>
      );
    }

    default:
      return event.type;
  }
};

const Action = ({
  triggeredBy,
}: {
  triggeredBy: EventComplete['triggered_by'];
}) => {
  const auth = useSharedAuth();

  return (
    <p className='font-bold'>
      {triggeredBy?.id === auth?.user?.id
        ? `${auth.user.first_name} ${auth.user.last_name} `
        : `${triggeredBy?.first_name || ''}${
            triggeredBy?.last_name ? `${triggeredBy?.last_name || ''}` : ''
          } `}
      <span className='font-normal'>
        <EventAction />
      </span>
    </p>
  );
};

const Item = ({
  children,
  eventsCount,
  index,
  event,
}: {
  children: React.ReactNode;
  index: number;
  eventsCount: number;
  event: EventComplete;
}) => {
  const isNonLatest = index + 1 !== eventsCount;

  return (
    <ActivityContext.Provider value={{ event, eventsCount, index }}>
      <div
        className={cn('relative', {
          'h-10': !isNonLatest,
          'border-l-2': index !== 0 || (index === 0 && eventsCount > 1),
        })}
      >
        {children}
      </div>
    </ActivityContext.Provider>
  );
};

const TimeAgo = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn('text-xs flex flex-end justify-end !mt-[2.5px]', className)}
    >
      <p className='text-slate-500'>{children}</p>
    </div>
  );
};

export const Activity = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-col'>{children}</div>;
};

Activity.Item = Item;
Activity.Content = Content;
Activity.Marker = Marker;
Activity.Header = Header;
Activity.TriggeredByAvatar = TriggeredByAvatar;
Activity.Action = Action;
Activity.TimeAgo = TimeAgo;
Activity.Detail = EventDetail;
