import { CHILDREN_WINDOW_DONE_WORD_TOKEN } from 'piramid-constants';
import { useCallback } from 'react';
import { Button, useToast } from 'ui';
import { useIntegrationBindings } from '../contexts/IntegrationBindingsContext';

export const ConnectOAuthIntegrationButton = ({
  oauth_url,
  integrationName,
}: {
  oauth_url: string;
  integrationName: string;
}) => {
  const { onIntegrationConnected } = useIntegrationBindings();

  const { toast } = useToast();

  const onClick = useCallback(() => {
    const features = {
      popup: 'yes',
      width: 600,
      height: 700,
      top: 'auto',
      left: 'auto',
      toolbar: 'no',
      menubar: 'no',
    };

    const strWindowsFeatures = Object.entries(features)
      .reduce((str, [key, value]) => {
        if (value == 'auto') {
          if (key === 'top') {
            const v = Math.round(window.innerHeight / 2 - features.height / 2);
            str += `top=${v},`;
          } else if (key === 'left') {
            const v = Math.round(window.innerWidth / 2 - features.width / 2);
            str += `left=${v},`;
          }
          return str;
        }

        str += `${key}=${value},`;
        return str;
      }, '')
      .slice(0, -1);

    window.open(oauth_url, '_blank', strWindowsFeatures);

    const handleMessage = (event: MessageEvent<any>) => {
      console.log('received message!', event);

      if (event.data === CHILDREN_WINDOW_DONE_WORD_TOKEN) {
        console.log('done detected!');

        toast({
          title: 'Integración conectada',
          description: `Se estableció correctamente la conexión con ${integrationName}`,
        });

        onIntegrationConnected();
        window.removeEventListener('message', handleMessage);
      }
    };

    window.addEventListener('message', handleMessage);
  }, []);

  return <Button onClick={onClick}>Conectar cuenta</Button>;
};
