import { FontFamily } from 'database';
import {
  Inter,
  Roboto_Mono,
  Roboto,
  Space_Grotesk,
  Space_Mono,
  Playfair_Display,
  Noto_Color_Emoji,
  Prata,
  Glegoo,
  Roboto_Flex,
  Roboto_Condensed,
  Roboto_Slab,
  IBM_Plex_Sans_Condensed,
  Ubuntu_Condensed,
  Barlow_Condensed,
  Saira_Condensed,
} from 'next/font/google';

export interface Font {
  font: FontFamily;
  className: string;
  variable?: string;
}

export const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

export const roboto = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const roboto_classname = roboto.className;

export const roboto_mono = Roboto_Mono({ subsets: ['latin'], display: 'swap' });

export const space_grotesk = Space_Grotesk({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const space_mono = Space_Mono({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

// export const noto_color_emoji = Noto_Color_Emoji({
//   subsets: ['emoji'],
//   display: 'swap',
//   weight: ['400'],
// })

export const playfair_display = Playfair_Display({
  subsets: ['latin'],
  display: 'swap',
});

export const prata = Prata({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400'],
});

export const glegoo = Glegoo({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const roboto_condensed = Roboto_Condensed({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const roboto_slab = Roboto_Slab({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const ibm_plex_sans_condensed = IBM_Plex_Sans_Condensed({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
  variable: '--font-theme',
});

export const ibm_plex_sans_condensed_classname =
  ibm_plex_sans_condensed.className;

export const ibm_plex_sans_condensed_var = ibm_plex_sans_condensed.variable;

export const ubuntu_condensed = Ubuntu_Condensed({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400'],
});

export const barlow_condensed = Barlow_Condensed({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

export const saira_condensed = Saira_Condensed({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700'],
});

const config = {
  variable: '--font-theme',
};

export const fonts: Font[] = [
  {
    ...config,
    font: 'inter',
    className: inter.className,
  },
  {
    ...config,
    font: 'barlow_condensed',
    className: barlow_condensed.className,
  },
  {
    ...config,
    font: 'glegoo',
    className: glegoo.className,
  },
  {
    ...config,
    font: 'ibm_plex_sans_condensed',
    className: ibm_plex_sans_condensed.className,
  },
  {
    ...config,
    font: 'playfair_display',
    className: playfair_display.className,
  },
  {
    ...config,
    font: 'prata',
    className: prata.className,
  },
  {
    ...config,
    font: 'roboto',
    className: roboto.className,
  },
  {
    ...config,
    font: 'robot_flex',
    className: roboto.className,
  },
  {
    ...config,
    font: 'roboto_condensed',
    className: roboto_condensed.className,
  },
  {
    ...config,
    font: 'roboto_mono',
    className: roboto_mono.className,
  },
  {
    ...config,
    font: 'roboto_slab',
    className: roboto_slab.className,
  },
  {
    ...config,
    font: 'saira_condensed',
    className: saira_condensed.className,
  },
  {
    ...config,
    font: 'space_grotesk',
    className: space_grotesk.className,
  },
  {
    ...config,
    font: 'space_mono',
    className: space_mono.className,
  },
  {
    ...config,
    font: 'ubuntu_condensed',
    className: ubuntu_condensed.className,
  },
];

export const fontClassName = (font: FontFamily): string => {
  const target = fonts.find((f) => f.font === font);

  return target?.className || '';
};
