import {
  Icon,
  IconCar,
  IconCertificate,
  IconCoffin,
  IconIdBadge2,
  IconLicense,
  IconReportMedical,
} from '@tabler/icons-react';
import { UnionToIntersection } from '@tanstack/react-table';
import { ListFilesResponseBody } from 'bff';
import { InferrableEntities } from 'database';
import { useTranslations } from 'i11n';
import { Separator } from 'ui';
import {
  EmptyStateText,
  FileDetailTab,
  FileDetailTabTitle,
} from './FileDetail';

type Entity = ListFilesResponseBody['files'][number]['entities'][number];

const iconsByInferrableEntity: {
  [K in InferrableEntities]: Icon;
} = {
  coverage_certificate: IconCertificate,
  death_certificate: IconCoffin,
  medical_certificate: IconReportMedical,
  national_identity_card: IconIdBadge2,
  vehicle: IconCar,
  vehicle_id_card_back: IconLicense,
  vehicle_id_card_front: IconLicense,
};

export const InferredEntities = ({ entities }: { entities: Entity[] }) => {
  return (
    <FileDetailTab>
      <FileDetailTabTitle>Entidades</FileDetailTabTitle>
      {entities.length > 0 ? (
        entities.map((e) => <InferredEntityCard entity={e} />)
      ) : (
        <EmptyStateText>No se encontraron entidades</EmptyStateText>
      )}
    </FileDetailTab>
  );
};

export const InferredEntityCard = ({ entity }: { entity: Entity }) => {
  const t = useTranslations();

  const entityFields: object = (entity as UnionToIntersection<typeof entity>)[
    entity.entity_type
  ];

  const Icon = iconsByInferrableEntity[entity.entity_type];

  return (
    <div>
      <div className='border shadow rounded-md w-full text-left flex flex-col py-2'>
        <div className='relative flex flex-col space-y-4'>
          <div className='flex flex-row items-center px-3'>
            <Icon className='text-2xl' />
            <p className='font-bold ml-2'>
              {t(`entities.${entity.entity_type}.label`)}
            </p>
          </div>

          <Separator className='my-2' />

          <div className='grid grid-cols-2 gap-3 text-xs px-3'>
            {Object.entries(entityFields).map(([key, value]) => {
              return (
                <div className='flex flex-col' key={key}>
                  <span>{t(`entities.${entity.entity_type}.${key}`)}</span>
                  <span className='font-bold'>{value || '-'}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
