'use client';

import { useMemo } from 'react';
import { DashboardLayout, LayoutTabItem } from './DashboardLayout';
import { Permission } from 'database';
import { useSharedAuth } from '../contexts/SharedAuthContext';

export const CustomerDashboardLayout = ({
  logout,
  children,
}: {
  logout: () => Promise<any>;
  children: React.ReactNode;
}) => {
  const { user } = useSharedAuth();

  const tabs = useMemo(() => {
    const intermediateTabs: LayoutTabItem[] = [
      {
        withoutClientSideRouting: true,
        label: 'Reclamos',
        href: '/claims',
        show: (permissions: Permission[]) =>
          (permissions.some((p) => p.type === 'can_read_claim') &&
            user.companyEntrypoints!.some(
              (entrypoint) => entrypoint === 'claim',
            )) ||
          user.type === 'third',
      },
      {
        withoutClientSideRouting: true,
        label: 'Denuncias',
        href: '/complaints',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_complaint') &&
          user.companyEntrypoints!.some(
            (entrypoint) => entrypoint === 'complaint',
          ),
      },
    ];

    return intermediateTabs.filter((tab) => tab.show(user.role.permissions));
  }, [user]);

  return (
    <DashboardLayout tabs={tabs} logout={logout}>
      {children}
    </DashboardLayout>
  );
};
