import React, { HTMLProps, useState } from 'react';
import { Skeleton, Spinner, cn } from 'ui';

export const IFrameLoader = React.forwardRef<
  HTMLIFrameElement,
  HTMLProps<HTMLIFrameElement> & { url: string }
>(({ url, ...props }, ref) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className='relative w-full h-full'>
      <div
        className={cn(
          'absolute top-0 left-0 w-full h-full bg-card z-10 flex items-center justify-center',
          {
            'opacity-0 pointer-events-none touch-none z-0': !isLoading,
          },
        )}
      >
        <div className='relative w-full h-full'>
          <div className='w-full h-full flex items-center justify-center absolute z-[60] bg-card'>
            <Spinner />
          </div>
          <div className='space-y-2 w-full h-full'>
            <Skeleton className='h-[500px] w-full' />
          </div>
        </div>
      </div>
      <iframe
        onLoad={() => setIsLoading(false)}
        width='100%'
        height='100%'
        style={{
          border: 0,
          overflow: 'hidden',
          minHeight: '500px',
          minWidth: '600px',
        }}
        src={url}
        {...props}
        ref={ref}
      />
    </div>
  );
});
