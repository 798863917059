import { ColorThemeType } from 'database';
import { ReactNode } from 'react';
import { Button, cn } from 'ui';

type BackgroundColorEquivalence = {
  [K in ColorThemeType]: string;
};

const equivalences: BackgroundColorEquivalence = {
  blue: 'bg-blue-200',
  gray: 'bg-gray-200',
  green: 'bg-green-200',
  red: 'bg-red-200',
  slate: 'bg-slate-200',
  neutral: 'bg-neutral-200',
  rose: 'bg-rose-200',
  orange: 'bg-orange-200',
  stone: 'bg-stone-200',
  violet: 'bg-violet-200',
  yellow: 'bg-yellow-200',
  zinc: 'bg-zinc-200',
};

export const themeEquivalences: BackgroundColorEquivalence = {
  blue: 'theme-blue',
  gray: 'theme-gray',
  green: 'theme-green',
  red: 'theme-red',
  slate: 'theme-slate',
  neutral: 'theme-neutral',
  rose: 'theme-rose',
  orange: 'theme-orange',
  stone: 'theme-stone',
  violet: 'theme-violet',
  yellow: 'theme-yellow',
  zinc: 'theme-zinc',
};

export const backgroundColorPicker = (color: ColorThemeType) => {
  return equivalences[color];
};

export const borderColorPicker = (color: ColorThemeType) => {
  return equivalences[color];
};

export const themeColorPicker = (color: ColorThemeType) => {
  return themeEquivalences[color];
};

export const ColorButton = ({
  color,
  children,
}: {
  color: ColorThemeType;
  children?: ReactNode | undefined;
}) => {
  return (
    <Button
      className={cn(backgroundColorPicker(color), 'text-xs')}
      size={'xs'}
      variant='outline'
    >
      {children}
    </Button>
  );
};
