import { ComboboxOption } from 'ui';

export function filterEnumOpts(
  opts: ComboboxOption[],
  search: string,
): ComboboxOption[] {
  return !search
    ? opts
    : opts.filter((opt) =>
        opt.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
      );
}
