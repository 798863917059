'use client';
import { FileTypes } from 'database';
import { useCallback, useState } from 'react';
import { nativeEnumToArray } from 'utils';
import { FileExtended, FileUploadResponse } from '../components/UploadFile';
import { TokenizeFileResponseBody } from 'bff';
import { ComboboxOption } from 'ui';

interface LocalFile {
  name: string;
  size: number;
  token: string;
  tags: ComboboxOption[];
}

export const useFileUpload = ({
  maxFiles = 5,
  maxTagsPerFile = 2,
  fileTypes = nativeEnumToArray(FileTypes),
  tokenizer,
  onValueChange,
  defaultValue = [],
}: {
  tokenizer: (file: {
    name: string;
    size: number;
  }) => Promise<TokenizeFileResponseBody>;
  maxFiles?: number;
  fileTypes?: FileTypes[];
  maxTagsPerFile?: number;
  onValueChange?: (files: LocalFile[]) => void;
  defaultValue?: LocalFile[];
}) => {
  const [files, setFiles] = useState<LocalFile[]>(defaultValue);

  const onRemoveFile = useCallback((index: number) => {
    setFiles((prev) => {
      const next = prev.filter((_, iterIndex) => iterIndex !== index);

      if (onValueChange) {
        onValueChange(next);
      }

      return next;
    });
  }, []);

  const onUpdateFile = useCallback(
    (index: number, file: Partial<FileExtended>) =>
      setFiles((prev) => {
        const next = prev.map((prevFile, prevIterIndex) => {
          if (prevIterIndex !== index) {
            return prevFile;
          }

          return {
            ...prevFile,
            ...file,
          };
        });

        if (onValueChange) {
          onValueChange(next);
        }

        return next;
      }),
    [],
  );

  const onUploadFile = useCallback(
    async (file: File): Promise<FileUploadResponse> => {
      const { fields, presigned_url, token, fetch_url } = await tokenizer({
        name: file.name,
        size: file.size,
      });

      const formData = new FormData();

      for (const key in fields) {
        const value = fields[key as keyof typeof fields];

        formData.append(key, value);
      }

      formData.append('file', file);

      await fetch(presigned_url, {
        method: 'POST',
        body: formData,
      });

      setFiles((files) => {
        const next = files.concat([
          {
            name: file.name,
            size: file.size,
            token,
            tags: [],
          },
        ]);

        if (onValueChange) {
          onValueChange(next);
        }

        return next;
      });

      return {
        url: fetch_url,
        token,
      };
    },
    [],
  );

  return {
    defaultValue: files,
    maxTagsPerFile,
    fileTypes,
    onRemoveFile,
    maxFiles,
    onUploadFile,
    files,
    setFiles,
    onUpdateFile,
  };
};
