import {
  ColumnDef,
  ColumnDefTemplate,
  HeaderContext,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

export const useColumnsController = <T extends object>(
  initialColumns: ColumnDef<T>[],
  initialWhitelist: string[] = [],
) => {
  const selectable = useMemo(
    () => initialColumns.filter((c) => !!c.header),
    [],
  );

  const [selected, setSelected] = useState(() =>
    selectable
      .filter((selectable) =>
        initialWhitelist.some((selected) => selectable.id === selected),
      )
      .map((c) => c.id),
  );

  const specs = useMemo(() => {
    return initialColumns.filter((c) => selected.includes(c.id) || !c.header);
  }, [selected.length]);

  return {
    specs,
    getControllerProps: () => {
      return {
        selected,
        setSelected,
        selectable,
      };
    },
  };
};

export interface UseColumnsControllerProps<T> {
  selected: (ColumnDefTemplate<HeaderContext<T, unknown>> | undefined)[];
  setSelected: React.Dispatch<
    React.SetStateAction<
      (ColumnDefTemplate<HeaderContext<T, unknown>> | undefined)[]
    >
  >;
  selectable: ColumnDef<T>[];
}
