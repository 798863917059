'use client';

import React from 'react';
import { cn } from 'ui';
import { cva, VariantProps } from 'class-variance-authority';

const variants = cva('', {
  variants: {
    size: {
      sm: 'w-4 h-4',
      md: 'w-6 h-6',
      lg: 'w-8 h-8',
      '2xl': 'w-12 h-12',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface PiramidLogoProps extends VariantProps<typeof variants> {
  className?: string;
  variant?: 'black' | 'white';
}

export const PiramidLogo = ({
  variant = 'black',
  className,
  size,
}: PiramidLogoProps) => {
  switch (variant) {
    case 'black':
      return (
        <span
          className={cn(
            variants({
              size,
              className,
            }),
          )}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            data-name='Capa 1'
            viewBox='0 0 415.25 374.85'
          >
            <path
              fill='#1a1b1e'
              d='M218.82 38.33L374.83 277.34 218.82 357.64 218.82 38.33z'
            ></path>
            <path
              fill='#1a1b1e'
              d='M230 76.07l128.59 197L230 339.24V76.07M207.59.59v375.46l32.73-16.85L368.9 293l22.1-11.37-13.61-20.85L248.85 63.8 207.59.59z'
            ></path>
            <path
              fill='#1a1b1e'
              d='M185.21 76v263.19L56.63 273 185.21 76M207.67.54l-41.26 63.2-128.59 197-13.6 20.84L46.35 293l128.58 66.18L207.67 376V.54z'
            ></path>
          </svg>
        </span>
      );

    case 'white':
      return (
        <span
          className={cn(
            variants({
              size,
              className,
            }),
          )}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            data-name='Capa 1'
            viewBox='0 0 415.25 374.85'
          >
            <path
              fill='#fff'
              d='M218.85 17.67L375.29 257.33 218.85 337.85 218.85 17.67z'
            ></path>
            <path
              fill='#fff'
              d='M230.1 55.51L359 253l-128.9 66.39V55.51m-22.51-75.68v376.48l32.82-16.9 128.93-66.36 22.2-11.42-13.65-20.91L249 43.2l-41.41-63.37z'
            ></path>
            <path
              fill='#fff'
              d='M185.15 55.46v263.88L56.22 253 185.15 55.46m22.52-75.68L166.3 43.15 37.36 240.67l-13.64 20.91L45.91 273l128.94 66.36 32.82 16.9V-20.22z'
            ></path>
          </svg>
        </span>
      );
  }
};
