import { FiltersVisibilityControllerProps } from '@/hooks/useFiltersVisibility';
import { IconFilter } from '@tabler/icons-react';
import { useTranslations } from 'i11n';
import { FancyBox } from 'ui';
export const FiltersVisibilityController = <
  TFilterKey extends string,
  TFilterValue extends object,
>({
  setFilters,
  filterList,
  filters,
  setLastFilter,
}: FiltersVisibilityControllerProps<TFilterKey, TFilterValue> & {
  setLastFilter?: (value: string) => void;
}) => {
  const t = useTranslations();

  const translate = (filters: TFilterKey[]) =>
    filters.map((filter) => ({
      label: t(`filters.${filter}`),
      value: filter,
    }));

  return (
    <FancyBox
      LeftIcon={IconFilter}
      btnClassName='border-dashed'
      pluralLabel='filtros aplicados'
      singularLabel='1 filtro aplicado'
      inputPlaceholder='Buscar filtros'
      emptyStateMessage='Ningún filtro'
      selectedValues={translate(filters)}
      onValueChange={(selected) => {
        setFilters(selected.map((opt) => opt.value));
      }}
      options={translate(filterList)}
      setLastFilter={setLastFilter}
    />
  );
};
