import { IconCircleFilled } from '@tabler/icons-react';
import { useTranslations } from 'i11n';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn,
} from 'ui';
import {
  EmptyStateText,
  FileDetailTab,
  FileDetailTabTitle,
} from './FileDetail';
import { ListFilesResponseBody } from 'bff';

type Validation = ListFilesResponseBody['files'][number]['validations'][number];

export const EntityValidations = ({
  validations,
}: {
  validations: Validation[];
}) => {
  const t = useTranslations();

  return (
    <FileDetailTab>
      <FileDetailTabTitle>Validaciones</FileDetailTabTitle>
      <div className='flex flex-col space-y-2'>
        {validations.length > 0 ? (
          validations.map((validation) => {
            return (
              <div
                className={cn('flex flex-row space-x-2 items-center')}
                key={validation.id}
              >
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <div className='flex flex-row items-center space-x-2 text-sm'>
                      <TooltipTrigger>
                        <IconCircleFilled
                          className={cn('w-4 h-4', {
                            'text-green-400': validation.valid,
                            'text-red-400': !validation.valid,
                          })}
                        />
                      </TooltipTrigger>
                      <span>{t(`validations.${validation.validation}`)}</span>
                    </div>
                    <TooltipContent>
                      {validation.valid ? 'Aprobado' : 'Desaprobado'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            );
          })
        ) : (
          <EmptyStateText>
            No se calcularon validaciones para este archivo
          </EmptyStateText>
        )}
      </div>
    </FileDetailTab>
  );
};
