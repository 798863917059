'use client';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredFieldMessage } from 'piramid-constants';
import { useApiError } from '../hooks/useApiError';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInRequestBody } from 'bff';
import { SignInWithMicrosoft } from './SignInWithMicrosoft';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export interface LoginUserFormProps {
  loginUser: (data: FormData) => Promise<any>;
  signInIDP: SignInIDPFn;
  forgotPasswordHref?: string;
}

export const LoginUserForm = ({
  loginUser,
  signInIDP,
  forgotPasswordHref,
}: LoginUserFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSubmit = (data: FormData) => {
    setIsLoading(true);

    loginUser(data)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  return (
    <>
      <div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='w-full grid grid-cols-1 gap-4'
          >
            <FormField
              control={form.control}
              name='email'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name='password'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Contraseña</FormLabel>
                    <FormControl>
                      <Input type='password' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <Link
              className='text-sm hover:underline'
              href={forgotPasswordHref || '/forgot-password'}
            >
              Olvide mi contraseña
            </Link>

            <Button loading={isLoading} type='submit' className='mt-5'>
              Iniciar sesión
            </Button>
          </form>
        </Form>

        <SeparatorText>O también podes</SeparatorText>
      </div>
    </>
  );
};
