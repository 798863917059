export const getMissingValues = <T extends object>(
  obj: T,
  requiredKeys: (keyof T)[],
): (keyof T)[] => {
  const missingKeys: (keyof T)[] = [];

  for (const key in obj) {
    const value = obj[key];

    if (!value && requiredKeys.includes(key)) {
      missingKeys.push(key);
    }
  }

  return missingKeys;
};
