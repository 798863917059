import { RetrieveCompanyStyleResponseQuery } from 'bff';
import { cn } from 'ui';
import { backgroundColorPicker, borderColorPicker } from './ColorButton';

export const styleToClassName = (
  data: RetrieveCompanyStyleResponseQuery,
  withBgColor: boolean,
) => {
  return cn(
    borderColorPicker(data?.border_color!),
    `border rounded-${data?.border_radius}`,
    `font-${data?.font_family}`,
    withBgColor ? backgroundColorPicker(data?.primary_color!) : {},
  );
};
