import { IconPaperclip } from '@tabler/icons-react';
import { cn } from 'ui';
import { FileCardIcon } from './FileCard';

export const FileList = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={cn(className)}>{children}</div>;
};

export const FileListItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'border-[1px] h-10 justify-between mt-2 w-full pl-1 rounded-sm flex flex-row items-center text-card-foreground bg-card',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const FileListLabel = ({
  children,
  className,
  mimetype,
  withMimetypeIcon = true,
  withDefaultIcon = false,
}: {
  children: React.ReactNode;
  className?: string;
  mimetype?: string | undefined | null;
  withMimetypeIcon?: boolean;
  withDefaultIcon?: boolean;
}) => {
  return (
    <div className={cn('flex flex-row items-center w-56', className)}>
      {withMimetypeIcon && mimetype && <FileCardIcon mimetype={mimetype} />}
      {withDefaultIcon && <IconPaperclip className='mr-2 flex-shrink-0' />}
      <p className='text-xs truncate block'>{children}</p>
    </div>
  );
};
