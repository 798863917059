'use client';

import React, { useState } from 'react';
import { ResetPasswordFn, ResetPasswordForm } from './ResetPasswordForm';

export const ResetPassword = ({
  token,
  resetPassword,
}: {
  token: string;
  resetPassword: ResetPasswordFn;
}) => {
  const [changed, setChanged] = useState(false);

  if (changed) {
    return <p>Contraseña cambiada</p>;
  }

  return (
    <ResetPasswordForm
      token={token}
      resetPassword={async (query, body) =>
        resetPassword(query, body).then((res) => {
          setChanged(true);
          return res;
        })
      }
    />
  );
};
