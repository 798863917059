'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { requiredFieldMessage } from 'piramid-constants';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from 'ui';
import { z } from 'zod';
import { useApiError } from '../hooks/useApiError';
import {
  RequestResetPasswordToken,
  ResetUserPasswordRequestBody,
  ResetUserPasswordResponseBody,
  resetUserPasswordRequestBody,
} from 'bff';

export type ResetPasswordFn = (
  params: RequestResetPasswordToken,
  body: ResetUserPasswordRequestBody,
) => Promise<ResetUserPasswordResponseBody>;

export const ResetPasswordForm = ({
  resetPassword,
  token,
}: {
  resetPassword: ResetPasswordFn;
  token: string;
}) => {
  const form = useForm<ResetUserPasswordRequestBody>({
    resolver: zodResolver(resetUserPasswordRequestBody),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSubmit = useCallback((data: ResetUserPasswordRequestBody) => {
    setIsLoading(true);

    console.log('data', data);

    resetPassword({ token }, data)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Form {...form}>
      <form
        className='w-full space-y-6'
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name='password'
          defaultValue=''
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Contraseña</FormLabel>
                <FormControl>
                  <Input {...field} type='password' />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name='passwordConfirmation'
          defaultValue=''
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Confirmar Contraseña</FormLabel>
                <FormControl>
                  <Input {...field} type='password' />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Button loading={isLoading} type='submit' className='mt-6'>
          Cambiar contraseña
        </Button>
      </form>
    </Form>
  );
};
