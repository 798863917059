import { FileWithURL } from 'bff';
import { UpdateNote } from 'database';
import { Separator } from 'ui';
import {
  ResourceUpdateCard,
  ResourceUpdateCardAuthor,
  ResourceUpdateCardFile,
  ResourceUpdateCardMessage,
} from './ResourceUpdateCard';

interface UpdateNoteMinimal
  extends Pick<UpdateNote, 'id' | 'message' | 'created_at'> {
  files: FileWithURL[];
  published_by: {
    email: string;
  };
}

export const UpdateNotesList = ({
  updateNotes,
}: {
  updateNotes: UpdateNoteMinimal[];
}) => {
  return (
    <div>
      {updateNotes.map((release, index) => {
        return (
          <div key={release.id}>
            <ResourceUpdateCard>
              <ResourceUpdateCardMessage>
                {release.message}
              </ResourceUpdateCardMessage>
              <div className='flex flex-col space-y-1'>
                {release.files.map((f) => (
                  <ResourceUpdateCardFile key={f.id} file={f} />
                ))}
              </div>

              <ResourceUpdateCardAuthor
                date={release.created_at}
                email={release.published_by.email}
              />
            </ResourceUpdateCard>
            {index + 1 !== updateNotes.length && (
              <Separator className='my-10' />
            )}
          </div>
        );
      })}
    </div>
  );
};
