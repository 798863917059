'use client';

import { Button } from 'ui';
import { Router } from 'next/router';
import { useEffect } from 'react';
import { useGoogle } from '../hooks/useGoogle';
import { GoogleIcon } from './GoogleIcon';

export const GoogleBtn = (props: {
  isLoading?: boolean;
  onAccept: (access_token: string) => void;
  small?: boolean;
}) => {
  const { initGoogleClient, googleClient } = useGoogle(props.onAccept);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = (e) => initGoogleClient();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [Router]);

  console.log('props.loading', props.isLoading);

  return (
    <Button
      loading={props.isLoading}
      variant={'outline'}
      className='w-full'
      onClick={() => (!!googleClient ? googleClient.requestAccessToken() : {})}
    >
      <GoogleIcon
        style={{
          width: !props.small ? '1.6rem' : '1.3rem',
        }}
      />
      <span className='ml-2 text-sm'>Ingresar con Google</span>
    </Button>
  );
};
