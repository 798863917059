import {
  ChoiceOption,
  ChoiceOptionContainer,
  ChoiceOptionSelectedMarker,
  ChoiceOptionValue,
} from './ChoiceOption';

export interface SelectOption<T = ChoiceOptionValue> {
  label: string;
  value: T;
}

export type ComponentSize = 'xs' | 'sm' | 'md';

export const ChoiceOptions = <T, M extends boolean>({
  options,
  value,
  onValueChange,
  multiple,
  disabled = false,
  size,
}: {
  disabled?: boolean;
  multiple?: M;
  onValueChange?: (value: M extends true ? T[] : T) => void;
  options: SelectOption<T>[];
  value: M extends true ? T[] : T;
  size?: ComponentSize;
}) => {
  return (
    <ul className='flex flex-col space-y-2 w-full'>
      {options.map((option, index) => {
        const getSelected = () => {
          if (multiple) {
            return (value as T[]).some((selected) => selected === option.value);
          }

          return (value as T) === option.value;
        };

        const selected = getSelected();

        return (
          //TODO: refactor and create a context for each item
          <ChoiceOptionContainer
            data-choice-option={option.value}
            size={size}
            selected={selected}
            disabled={disabled}
            onClick={() => {
              if (disabled || !onValueChange) {
                return;
              }

              if (selected) {
                onValueChange(
                  (multiple
                    ? (value as T[]).filter(
                        (selected) => selected !== option.value,
                      )
                    : option.value) as any,
                );
              }

              if (!selected) {
                onValueChange(
                  (multiple
                    ? [...(value as T[]), option.value]
                    : option.value) as any,
                );
              }
            }}
            key={index}
          >
            <ChoiceOptionSelectedMarker size={size} selected={selected} />
            <ChoiceOption
              size={size}
              className='items-center'
              selected={selected}
              index={index}
              key={index}
            >
              {option.label}
            </ChoiceOption>
          </ChoiceOptionContainer>
        );
      })}
    </ul>
  );
};
