import { ComboboxOption } from 'ui';

export function mapEnumOpts<T extends string[] | ReadonlyArray<string>>(
  list: T,
  translate?: (value: T[number]) => string,
): ComboboxOption[] {
  return (list || []).map((l) => ({
    label: translate ? translate(l) : l,
    value: l,
  }));
}
