import { ListAppraisalUpdatesResponseBody } from 'bff';
import { Separator } from 'ui';
import {
  ResourceUpdateCard,
  ResourceUpdateCardAuthor,
  ResourceUpdateCardFile,
  ResourceUpdateCardMessage,
} from './ResourceUpdateCard';
import { UpdateNotesList } from './UpdateNotesList';

type AppraisalUpdate =
  ListAppraisalUpdatesResponseBody['appraisal_updates'][number];

export const AppraisalUpdatesList = ({
  appraisal_updates,
}: {
  appraisal_updates: AppraisalUpdate[];
}) => {
  return <UpdateNotesList updateNotes={appraisal_updates} />;
};
