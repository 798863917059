'use client';

import { PaginationState } from '@tanstack/react-table';
import { Pagination, PaginationMeta } from 'bff';
import { useState } from 'react';
import { DataTablePaginationProps } from 'ui';

export interface PaginationMetaExtended extends PaginationMeta {
  page_size: number;
}

export const useStubTable = <T>(
  initMeta: PaginationMeta,
  initSize: number = 10,
) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: initMeta.current_page - 1,
    pageSize: initSize,
  });

  const [paginationMeta, setPaginationMeta] =
    useState<PaginationMeta>(initMeta);

  return {
    paginationMeta,
    setPaginationMeta,
    pagination,
    setPagination,
    table: {
      setPageSize: (size) =>
        setPagination((prev) => ({ ...prev, pageSize: size })),
      setPageIndex: (index) =>
        setPagination((prev) => ({ ...prev, pageIndex: index })),
      previousPage: () =>
        setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 })),
      nextPage: () =>
        setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 })),
      getCanNextPage: () => !paginationMeta.is_last_page,
      getCanPreviousPage: () => !paginationMeta.is_first_page,
      getPageCount: () => paginationMeta.page_count,
      getState: () => ({
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
      }),
    } satisfies DataTablePaginationProps<T>['table'],
  };
};
