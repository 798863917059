import { User } from 'database';
import { Avatar, AvatarFallback, AvatarImage, AvatarProps } from 'ui';

export const UserAvatar = ({
  data,
  ...props
}: {
  data: Pick<User, 'profile_photo' | 'first_name' | 'last_name'>;
} & AvatarProps) => {
  return (
    <Avatar {...props}>
      <AvatarImage src={data!.profile_photo!} />
      <AvatarFallback>
        {`${data!.first_name.charAt(0)}${data!.last_name?.charAt(0) || ''}`}
      </AvatarFallback>
    </Avatar>
  );
};
