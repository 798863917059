'use client';
import { IconFileDescription, IconPaperclip } from '@tabler/icons-react';
import {
  FetchInsurerComplaintDetailResponseBody,
  RetrieveComplaintDetailResponseBody,
} from 'bff';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import {
  ClaimSummary,
  FileList,
  FileListItem,
  FileListLabel,
} from 'shared-components';
import { ScrollArea } from 'ui';

export const ComplaintDetailCards = ({
  data,
}: {
  data: FetchInsurerComplaintDetailResponseBody;
}) => {
  const t = useTranslations();

  return (
    <ClaimSummary.CardList>
      <ClaimSummary.CardGroup>
        <ClaimSummary.Header>
          <ClaimSummary.CardGroupLabel>
            <ClaimSummary.Icon>
              <IconFileDescription />
            </ClaimSummary.Icon>
            Asegurado
          </ClaimSummary.CardGroupLabel>
        </ClaimSummary.Header>

        <ClaimSummary.CardGroupSeparator />
        <ClaimSummary.CardGroupFields>
          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Póliza</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.policyholder.policy_number}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>

          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Identificación</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.policyholder.identification}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>

          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Nombre</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.policyholder.first_name} {data.policyholder.last_name}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>

          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Correo</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.policyholder.email}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>
        </ClaimSummary.CardGroupFields>
      </ClaimSummary.CardGroup>

      <ClaimSummary.CardGroup>
        <ClaimSummary.Header>
          <ClaimSummary.CardGroupLabel>
            <ClaimSummary.Icon>
              <IconFileDescription />
            </ClaimSummary.Icon>
            Datos del siniestro
          </ClaimSummary.CardGroupLabel>
        </ClaimSummary.Header>

        <ClaimSummary.CardGroupSeparator />
        <ClaimSummary.CardGroupFields>
          {data.occurrence.sinister_date && (
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Fecha</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {format(
                  new Date(data.occurrence.sinister_date!),
                  'dd/MM/yyyy HH:mm',
                )}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
          )}

          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Ciudad</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.occurrence!.city}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>

          {data.type === 'vehicle' && (
            <>
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>Velocidad km/h</ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.occurrence!.speed}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>
                  Sentido de circulación
                </ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {t(
                    `circulation.direction.${
                      data.occurrence!.circulation_direction
                    }`,
                  )}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>
                  ¿Hubo intervención policial?
                </ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.occurrence!.police_intervention ? 'Sí' : 'No'}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>

              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>
                  Información Testigos
                </ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.occurrence!.witnesses_info}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            </>
          )}

          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Dirección</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.occurrence!.address}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>
          <ClaimSummary.CardAttribute>
            <ClaimSummary.CardLabel>Código Postal</ClaimSummary.CardLabel>
            <ClaimSummary.CardValue>
              {data.occurrence!.zip_code}
            </ClaimSummary.CardValue>
          </ClaimSummary.CardAttribute>
        </ClaimSummary.CardGroupFields>
      </ClaimSummary.CardGroup>

      <ClaimSummary.Damages damages={data.damages!} />

      <ClaimSummary.CardGroup>
        <ClaimSummary.Header>
          <ClaimSummary.CardGroupLabel>
            <ClaimSummary.Icon>
              <IconPaperclip />
            </ClaimSummary.Icon>
            Documentos
          </ClaimSummary.CardGroupLabel>
        </ClaimSummary.Header>
        <ClaimSummary.CardGroupSeparator />

        <FileList>
          <ScrollArea type='auto' className='h-96' orientation='vertical'>
            {data.documents.length > 0 ? (
              data.documents.map((document, index) => {
                return (
                  <a
                    className='group'
                    target='_blank'
                    key={document.id}
                    href={document.url}
                  >
                    <FileListItem className='group-hover:underline' key={index}>
                      <FileListLabel mimetype={document.mimetype!}>
                        {document.original_name}
                      </FileListLabel>
                    </FileListItem>
                  </a>
                );
              })
            ) : (
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel />
                <ClaimSummary.CardValue className='text-xs text-muted-foreground'>
                  No hay documentos adjuntos
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            )}
          </ScrollArea>
        </FileList>
      </ClaimSummary.CardGroup>
    </ClaimSummary.CardList>
  );
};
