import { EventComplete } from 'bff';
import { formatDistance } from 'date-fns';
import { Activity } from './Activity';

export const ActivityEventsList = ({ events }: { events: EventComplete[] }) => {
  return (
    <Activity>
      {events.map((event, index) => {
        return (
          <Activity.Item
            index={index}
            event={event}
            eventsCount={events.length}
            key={event.id}
          >
            <Activity.Marker />

            <Activity.Content>
              <Activity.Header>
                <Activity.TriggeredByAvatar triggeredBy={event.triggered_by} />
                <Activity.Action triggeredBy={event.triggered_by} />
                <Activity.TimeAgo className='mt-0 block'>
                  {formatDistance(new Date(), new Date(event.created_at))}
                </Activity.TimeAgo>
              </Activity.Header>
              <Activity.Detail />
              <div className='h-5' />
            </Activity.Content>
          </Activity.Item>
        );
      })}
    </Activity>
  );
};
