import { UseColumnsControllerProps } from '@/hooks/useColumnsController';
import { IconColumns } from '@tabler/icons-react';
import { ColumnDef } from '@tanstack/react-table';
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'ui';

export const ColumnsController = <T,>({
  setSelected,
  selected,
  selectable,
}: UseColumnsControllerProps<T>) => {
  const mapHeader = (column: ColumnDef<T>) => {
    return typeof column.header !== 'string'
      ? (column.meta['header'] as string)
      : (column.header as string);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size='md' variant='outline'>
          <IconColumns className='mr-2 h-4 w-4' />
          Vista
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        {selectable.map((column, index) => {
          const header = mapHeader(column);

          return (
            <DropdownMenuCheckboxItem
              key={index}
              className='capitalize'
              checked={selected.some((selected) => selected === column.id)}
              onCheckedChange={(value) => {
                if (value) {
                  setSelected([...selected, column.id]);
                }

                if (!value) {
                  setSelected(
                    selected.filter((selected) => selected !== column.id),
                  );
                }
              }}
            >
              {header}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
