'use client';

import { ListIntegrationsResponseBody } from 'bff';
import {
  Badge,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  cn,
} from 'ui';
import { ConnectOAuthIntegrationButton } from './ConnectOAuthIntegrationButton';
import { DisconnectOAuthIntegrationButton } from './DisconnectOAuthIntegrationButton';

export const IntegrationsList = ({
  data,
}: {
  data: ListIntegrationsResponseBody;
}) => {
  return (
    <div className='grid grid-cols-2 gap-6'>
      {data.integrations.map((integration) => {
        const [connection] = integration.connections;

        return (
          <Card key={integration.id} className='relative'>
            <div className='flex flex-row items-start justify-between mb-10'>
              <CardHeader>
                <CardTitle>{integration.name}</CardTitle>
                {integration.description && (
                  <CardDescription>{integration.description}</CardDescription>
                )}
              </CardHeader>
              <div className='p-6'>
                <div className='h-10 w-10'>
                  <img
                    className='rounded-full w-full h-full'
                    src={integration.banner.url}
                  />
                </div>
              </div>
            </div>

            <CardContent />

            <CardFooter className='absolute bottom-0'>
              {connection ? (
                <DisconnectOAuthIntegrationButton
                  integrationName={integration.name}
                  connectionId={connection.id}
                />
              ) : (
                <ConnectOAuthIntegrationButton
                  integrationName={integration.name}
                  oauth_url={integration.oauth_url!}
                />
              )}
            </CardFooter>
            <div className='absolute right-0 -top-3'>
              <Badge
                className={cn('text-xs', {
                  'bg-card': !connection,
                })}
                variant={connection ? 'default' : 'outline'}
              >
                {connection ? 'Conectado' : 'Desconectado'}
              </Badge>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
