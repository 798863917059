'use client';

import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';

export const usePagination = (props: { pageSize: number }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: props.pageSize,
  });

  return {
    pagination,
    setPagination,
  };
};
