'use client';

import { FetchContractDetailResponseBody } from 'bff';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import {
  Attribute,
  AttributeLabel,
  AttributeValue,
  FileListItem,
  FileListLabel,
  useNumberFormatter,
} from 'shared-components';

export const ContractDetail = ({
  data,
}: {
  data: FetchContractDetailResponseBody;
}) => {
  const formatter = useNumberFormatter();
  const t = useTranslations();

  return (
    <div className='grid grid-cols-3 gap-4'>
      <Attribute>
        <AttributeLabel>Nombre</AttributeLabel>
        <AttributeValue>{data.name}</AttributeValue>
      </Attribute>
      <Attribute>
        <AttributeLabel>Estado</AttributeLabel>
        <AttributeValue>{t(`rfp.status.${data.status}`)}</AttributeValue>
      </Attribute>
      <Attribute>
        <AttributeLabel>Proveedor</AttributeLabel>
        <AttributeValue>{data.vendor_contact.name}</AttributeValue>
      </Attribute>
      <Attribute>
        <AttributeLabel>Costo</AttributeLabel>
        <AttributeValue>
          {formatter.formatNumber(data.cost_value)} -{' '}
          {t(`cost.type.${data.cost_type}`)}
        </AttributeValue>
      </Attribute>
      <Attribute>
        <AttributeLabel>Fecha inicio</AttributeLabel>
        <AttributeValue>
          {format(new Date(data.start_date), 'dd/MM/yyyy')}
        </AttributeValue>
      </Attribute>
      <Attribute>
        <AttributeLabel>Fecha fin</AttributeLabel>
        <AttributeValue>
          {format(new Date(data.end_date), 'dd/MM/yyyy')}
        </AttributeValue>
      </Attribute>
      {data.notes && (
        <Attribute className='col-span-3'>
          <AttributeLabel>Notas</AttributeLabel>
          <AttributeValue>{data.notes}</AttributeValue>
        </Attribute>
      )}

      {data.files.length > 0 && (
        <Attribute className='col-span-3'>
          <AttributeLabel>Archivos</AttributeLabel>
          {data.files.map((document) => {
            return (
              <a
                className='group'
                target='_blank'
                key={document.id}
                href={document.url}
              >
                <FileListItem className='group-hover:underline'>
                  <FileListLabel mimetype={document.mimetype!}>
                    {document.original_name}
                  </FileListLabel>
                </FileListItem>
              </a>
            );
          })}
        </Attribute>
      )}
    </div>
  );
};
