'use client';

import { ClaimOfferList } from './ClaimOffer';
import { ListAppraisalReportResponseBody } from 'bff';
import {
  OfferAgreementCard,
  OfferAgreementCardActionActor,
  OfferAgreementCardAttribute,
  OfferAgreementCardAttributeLabel,
  OfferAgreementCardAttributeValue,
  OfferAgreementCardContent,
  OfferAgreementCardDocument,
  OfferAgreementCardFooter,
  OfferAgreementCardHeader,
  OfferAgreementCardStatus,
  OfferAgreementCardTitle,
} from './OfferAgreementCard';
import { currencyFormat } from 'utils';
import { format } from 'date-fns';

type AppraisalReport =
  ListAppraisalReportResponseBody['appraisal_reports'][number];

export const AppraisalReportList = ({
  appraisalReports,
  renderActions,
}: {
  appraisalReports: AppraisalReport[];
  renderActions?: React.FC<{
    appraisalReport: AppraisalReport;
  }>;
}) => {
  const acceptedAppraisalReport = appraisalReports.find(
    (a) => a.status === 'accepted',
  );

  return (
    <ClaimOfferList>
      {appraisalReports.map((appraisalReport) => {
        return (
          <OfferAgreementCard key={appraisalReport.id}>
            <OfferAgreementCardStatus status={appraisalReport.status} />
            <OfferAgreementCardHeader>
              <OfferAgreementCardTitle>
                Informe de evaluación - Version {appraisalReport.version}
              </OfferAgreementCardTitle>
            </OfferAgreementCardHeader>
            <OfferAgreementCardContent>
              {appraisalReport.risk && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Riesgo
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {appraisalReport.risk}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {appraisalReport.total_disability && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Incapacidad total
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {appraisalReport.total_disability}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {appraisalReport.appraised_total_value && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Monto total peritado
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {currencyFormat(
                      appraisalReport.appraised_total_value,
                      appraisalReport.type === 'claim'
                        ? appraisalReport.claim?.company?.country
                        : appraisalReport.complaint?.company?.country,
                    )}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {appraisalReport.accepted_at && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Fecha aceptación
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {format(
                      new Date(appraisalReport.accepted_at),
                      'dd/MM/yyyy HH:mm',
                    )}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {appraisalReport.rejected_at && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Fecha rechazo
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {format(
                      new Date(appraisalReport.rejected_at),
                      'dd/MM/yyyy HH:mm',
                    )}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}
            </OfferAgreementCardContent>
            <OfferAgreementCardFooter status={appraisalReport.status}>
              <OfferAgreementCardDocument
                mimetype={appraisalReport.report.mimetype!}
                created_by_first_name={appraisalReport.created_by.first_name}
                created_by_last_name={appraisalReport.created_by.last_name}
                name={appraisalReport.report.original_name}
                url={appraisalReport.report.url}
                accepted_at={appraisalReport.created_at}
              />

              {!!appraisalReport.rejected_reason &&
                !!appraisalReport.rejected_by && (
                  <div className='w-full space-y-2'>
                    <p className='text-xs italic'>
                      "{appraisalReport.rejected_reason}"
                    </p>
                    <OfferAgreementCardActionActor
                      date={appraisalReport.rejected_at!}
                      first_name={appraisalReport.rejected_by.first_name}
                      last_name={appraisalReport.rejected_by.last_name}
                    />
                  </div>
                )}

              {renderActions &&
                !acceptedAppraisalReport &&
                appraisalReport.status === 'pending' &&
                renderActions({
                  appraisalReport,
                })}
            </OfferAgreementCardFooter>
          </OfferAgreementCard>
        );
      })}
    </ClaimOfferList>
  );
};
