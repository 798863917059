import { useState } from 'react';
import { useApiError } from '../hooks/useApiError';
import { SignInIDPFn } from './SignInWithGoogle';
import { MicrosoftBtn } from './MicrosoftButton';

export const SignInWithMicrosoft = ({
  signInIDP,
  onDone,
}: {
  signInIDP: SignInIDPFn;
  onDone?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSignIn = (accessToken: string) => {
    setIsLoading(true);

    signInIDP({
      identity: {
        access_token: accessToken,
        provider: 'microsoft',
      },
    })
      .then(() => {
        if (onDone) {
          onDone();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  return <MicrosoftBtn isLoading={isLoading} onAccept={handleSignIn} />;
};
