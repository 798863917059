export const UnexpectedError = () => {
  return (
    <div className='h-screen flex items-center justify-center'>
      <div className='relative'>
        <h1 className='text-center text-3xl'>500</h1>
      </div>
      <div className='text-center p-1'>
        <h1>Algo salió mal</h1>
        <p className='text-lg my-5'>
          El equipo de Piramid ya fue notificado del error y lo solucionará lo
          antes posible. Disculpa por las molestias ocasionadas.
        </p>
      </div>
    </div>
  );
};
