'use client';
import { ListContractReportsResponseBody } from 'bff';
import React from 'react';
import { ClaimOfferList } from './ClaimOffer';
import {
  OfferAgreementCard,
  OfferAgreementCardActionActor,
  OfferAgreementCardAttribute,
  OfferAgreementCardAttributeLabel,
  OfferAgreementCardAttributeValue,
  OfferAgreementCardContent,
  OfferAgreementCardDocument,
  OfferAgreementCardFooter,
  OfferAgreementCardHeader,
  OfferAgreementCardStatus,
  OfferAgreementCardTitle,
} from './OfferAgreementCard';
import { format } from 'date-fns';

type ContractReport = ListContractReportsResponseBody['reports'][number];

export const ContractReportsList = ({
  reports,
  renderActions,
}: {
  reports: ContractReport[];
  renderActions?: React.FC<{
    contractReport: ContractReport;
  }>;
}) => {
  const acceptedAppraisalReport = reports.find((a) => a.status === 'accepted');

  return (
    <ClaimOfferList>
      {reports.map((report) => {
        return (
          <OfferAgreementCard key={report.id}>
            <OfferAgreementCardStatus status={report.status} />
            <OfferAgreementCardHeader>
              <OfferAgreementCardTitle>
                Reporte - Version {report.version}
              </OfferAgreementCardTitle>
            </OfferAgreementCardHeader>
            <OfferAgreementCardContent>
              {report.notes && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Notas
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {report.notes}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {report.accepted_at && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Fecha aceptación
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {format(new Date(report.accepted_at), 'dd/MM/yyyy HH:mm')}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}

              {report.rejected_at && (
                <OfferAgreementCardAttribute>
                  <OfferAgreementCardAttributeLabel>
                    Fecha rechazo
                  </OfferAgreementCardAttributeLabel>
                  <OfferAgreementCardAttributeValue>
                    {format(new Date(report.rejected_at), 'dd/MM/yyyy HH:mm')}
                  </OfferAgreementCardAttributeValue>
                </OfferAgreementCardAttribute>
              )}
            </OfferAgreementCardContent>
            <OfferAgreementCardFooter status={report.status}>
              <OfferAgreementCardDocument
                mimetype={report.file.mimetype!}
                created_by_first_name={report.created_by.first_name}
                created_by_last_name={report.created_by.last_name}
                name={report.file.original_name}
                url={report.file.url}
                accepted_at={report.created_at}
              />

              {!!report.rejected_reason && !!report.rejected_by && (
                <div className='w-full space-y-2'>
                  <p className='text-xs italic'>"{report.rejected_reason}"</p>
                  <OfferAgreementCardActionActor
                    date={report.rejected_at!}
                    first_name={report.rejected_by.first_name}
                    last_name={report.rejected_by.last_name}
                  />
                </div>
              )}

              {renderActions &&
                !acceptedAppraisalReport &&
                report.status === 'pending' &&
                renderActions({
                  contractReport: report,
                })}
            </OfferAgreementCardFooter>
          </OfferAgreementCard>
        );
      })}
    </ClaimOfferList>
  );
};
