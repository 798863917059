'use client';

import { useQuery } from '@tanstack/react-query';
import { AuthInfoResponseBody } from 'bff';
import { UserType } from 'database';
import { createContext, useContext, useMemo } from 'react';
import { getMissingValues } from '../utils/getMissingValues';

export const USER_QUERY_KEY = 'user';

export interface AuthContext {
  user: AuthInfoResponseBody;
  hasMissingFields: boolean;
}

const SharedAuthContext = createContext<AuthContext | undefined>(undefined);

export const mandatoryUserFields: (keyof AuthInfoResponseBody)[] = [
  'first_name',
  'last_name',
  'identification',
  'sex',
  'address',
  'zip_code',
  'cellphone',
  'nationality',
  'birth_date',
  'address',
  'state',
  'city',
];

export const useSharedAuth = () => useContext(SharedAuthContext)!;

export const SharedAuthProvider = ({
  children,
  user,
  fetchUser,
}: {
  children: React.ReactNode;
  user: AuthInfoResponseBody;
  fetchUser: (userType: UserType) => Promise<AuthInfoResponseBody>;
}) => {
  const { data } = useQuery({
    queryFn: () => fetchUser(user.type),
    queryKey: [USER_QUERY_KEY],
    initialData: user,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const hasMissingFields = useMemo(
    () => getMissingValues(user, mandatoryUserFields).length > 0,
    [],
  );

  return (
    <SharedAuthContext.Provider
      value={{
        user: data,
        hasMissingFields,
      }}
    >
      {children}
    </SharedAuthContext.Provider>
  );
};
