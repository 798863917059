import { useCallback, useState } from 'react';
import { useApiError } from 'shared-components';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  useToast,
} from 'ui';
import { useIntegrationBindings } from '../contexts/IntegrationBindingsContext';

export const DisconnectOAuthIntegrationButton = ({
  connectionId,
  integrationName,
}: {
  connectionId: number;
  integrationName: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const { handleError } = useApiError();

  const { disconnectIntegration, onIntegrationDisconnected } =
    useIntegrationBindings();

  const onClick = useCallback(() => {
    setIsLoading(true);

    disconnectIntegration({
      id: connectionId,
    })
      .then(() => {
        toast({
          title: 'Integración desconectada',
          description: `La conexión con la integración ${integrationName} ha sido desconectada`,
        });

        onIntegrationDisconnected();
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, [connectionId]);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={'outline'}>Desconectar cuenta</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            ¿Estas seguro que deseas desconectar esta integración?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Las funcionalidades de la plataforma que hagan uso de esta
            integración dejaran de funcionar. Este cambio es irreversible.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading}>Cancelar</AlertDialogCancel>
          <AlertDialogAction disabled={isLoading} asChild>
            <Button loading={isLoading} onClick={onClick}>
              Desconectar
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
