import {
  ListCompanyMessageTemplatesResponseBody,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
  applyConditionsToVariables,
  complaintPolicyholderSchema,
  tokenizeFileRequestBody,
} from 'bff';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  NumberField,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'ui';
import { FileInput } from './FileInput';
import { useFileUpload } from '../hooks/useFileUpload';
import { UploadFile } from './UploadFile';

type FormData = { variables: Record<string, any> };

export interface MessageTemplateFormContextProps {
  tokenizeFile: (
    data: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  form: UseFormReturn<FormData>;
  messageTemplate: Pick<
    ListCompanyMessageTemplatesResponseBody['message_templates'][number],
    'variables' | 'conditions' | 'validations'
  >;
}

const MessageTemplateFormContext = React.createContext<
  MessageTemplateFormContextProps | undefined
>(undefined);

export const useMessageTemplateForm = () =>
  React.useContext(MessageTemplateFormContext)!;

export const useMessageTemplateVariablesDefinition = ({
  messageTemplate,
  variablesValues,
  form,
}: {
  messageTemplate: MessageTemplateFormContextProps['messageTemplate'];
  variablesValues: Record<string, any>;
  form: UseFormReturn<FormData>;
}) => {
  const [variables, setVariables] = useState<
    (typeof messageTemplate)['variables']
  >(() =>
    applyConditionsToVariables({
      values: variablesValues,
      variables: messageTemplate.variables,
      conditions: messageTemplate.conditions,
    }),
  );

  useEffect(() => {
    const subscription = form.watch(({ variables }) =>
      setVariables(
        applyConditionsToVariables({
          values: variables!,
          variables: messageTemplate.variables,
          conditions: messageTemplate.conditions,
        }),
      ),
    );

    return () => subscription.unsubscribe();
  }, []);

  return variables;
};

export const MessageTemplateFormProvider = ({
  children,
  messageTemplate,
  form,
  tokenizeFile,
}: {
  children: React.ReactNode;
} & MessageTemplateFormContextProps) => {
  return (
    <MessageTemplateFormContext.Provider
      value={{
        form,
        messageTemplate,
        tokenizeFile,
      }}
    >
      {children}
    </MessageTemplateFormContext.Provider>
  );
};

interface VariableFieldInputSwitchProps {
  variable: ListCompanyMessageTemplatesResponseBody['message_templates'][number]['variables'][number];
  field: ControllerRenderProps<FormData, `variables.${string}`>;
}

const FileInputVariable = ({
  variable,
  field,
}: VariableFieldInputSwitchProps) => {
  const { tokenizeFile } = useMessageTemplateForm();

  console.log('file value', field.value);

  const fileControl = useFileUpload({
    maxFiles: 1,
    tokenizer: tokenizeFile,
    fileTypes: ['image', 'document', 'pdf'],
  });

  useEffect(() => {
    const [file] = fileControl.files;
    field.onChange(file?.token);
  }, [fileControl.files]);

  return <UploadFile {...fileControl} />;
};

const VariableFieldInputSwitch = ({
  variable,
  field,
}: VariableFieldInputSwitchProps) => {
  switch (variable.type) {
    case 'numeric':
      return (
        <NumberField
          {...field}
          formatOptions={{
            useGrouping: !!variable.numeric_format,
            ...(variable.numeric_format_currency && !!variable.numeric_format
              ? {
                  style: 'currency',
                  currency: variable.numeric_format_currency,
                }
              : {}),
          }}
        />
      );

    case 'text':
      return <Input type='text' {...field} />;

    case 'select':
      return (
        <Select onValueChange={(value) => field.onChange(value)}>
          <SelectTrigger>
            <SelectValue placeholder='Selecciona una opción' />
          </SelectTrigger>
          <SelectContent>
            {variable.options?.map((option) => {
              return <SelectItem value={option.name}>{option.name}</SelectItem>;
            })}
          </SelectContent>
        </Select>
      );

    case 'file':
      return <FileInputVariable field={field} variable={variable} />;
  }
};

export const MessageTemplateFormContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='flex flex-col space-y-4'>{children}</div>;
};

export const MessageTemplateForm = () => {
  const {
    messageTemplate: { variables },
    form,
  } = useMessageTemplateForm();

  return variables.map((variable) => {
    return (
      <FormField
        control={form.control}
        name={`variables.${variable.guid}`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{variable.name}</FormLabel>
              <FormControl>
                <VariableFieldInputSwitch field={field} variable={variable} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
    );
  });
};
