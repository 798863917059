import { Permission, PermissionType } from 'database';
import { useTranslations } from 'i11n';

export interface PermissionsWithLabels extends Permission {
  label: string;
}

export const usePermissions = (
  permissions: Permission[],
): PermissionsWithLabels[] => {
  const t = useTranslations();

  return permissions.map((p) => {
    return {
      ...p,
      label: t(`permissions.${p.type}`),
    };
  });
};
