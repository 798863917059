import { useCallback, useState } from 'react';
import { DEFAULT_LOCALE, DEFAULT_CURRENCY } from 'piramid-constants';

export const useNumberFormatter = (currency = DEFAULT_CURRENCY) => {
  const [formatter] = useState(
    new Intl.NumberFormat(DEFAULT_LOCALE, {
      style: 'currency',
      currency,
    }),
  );

  const formatNumber = useCallback(
    (value?: any) => (value != null ? formatter.format(Number(value)) : ''),
    [],
  );

  return {
    formatNumber,
  };
};
