'use client';
import { useTranslations } from 'i11n';
import { useMemo, useState } from 'react';
import { FileCardIcon } from 'shared-components';
import {
  Badge,
  Button,
  ComboboxCheckbox,
  ComboboxOption,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from 'ui';
import { nativeEnumToArray } from 'utils';
import { FileTypes } from 'database';
import { IconPlus } from '@tabler/icons-react';

export const DocumentTypesFilter = ({
  selectedValues,
  setSelectedValues,
}: {
  setSelectedValues: (selected: ComboboxOption[]) => void;
  selectedValues: ComboboxOption[];
}) => {
  const [open, setOpen] = useState(false);

  const t = useTranslations();

  const options = useMemo(
    () =>
      nativeEnumToArray(FileTypes).map((type) => {
        return {
          label: t(`file_types.${type}`),
          value: type,
        };
      }),
    [],
  );

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button variant='outline' size='sm' className='h-10 border-dashed'>
          <IconPlus className='mr-2 h-4 w-4' />
          Tipos
          {selectedValues.length > 0 && (
            <>
              <Separator orientation='vertical' className='mx-2 h-4' />
              <Badge
                variant='secondary'
                className='rounded-sm px-1 font-normal lg:hidden'
              >
                {selectedValues.length}
              </Badge>
              <div className='hidden space-x-1 lg:flex'>
                {selectedValues.length > 2 ? (
                  <Badge
                    variant='secondary'
                    className='rounded-sm px-1 font-normal'
                  >
                    {selectedValues.length} seleccionados
                  </Badge>
                ) : (
                  selectedValues
                    .filter((option) =>
                      selectedValues.some((ele) => ele.value === option.value),
                    )
                    .map((option, index) => (
                      <Badge
                        variant='secondary'
                        key={index}
                        className='rounded-sm px-1 font-normal'
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[200px] p-0' align='start'>
        <ComboboxCheckbox
          value={selectedValues}
          onClose={() => setOpen(false)}
          render={({ label, value }) => {
            console.log('value', value);

            return (
              <>
                <FileCardIcon lookupMode='rely' mimetype={value.toString()!} />
                {label}
              </>
            );
          }}
          placeholder='Buscar tipo'
          onSelect={({ value, label }) => {
            if (selectedValues.some((ele) => ele.value === value)) {
              setSelectedValues(
                selectedValues.filter((ele) => ele.value !== value),
              );
            } else {
              setSelectedValues([...selectedValues, { label, value }]);
            }
          }}
          loadOptions={async (search) =>
            options.filter((opt) =>
              opt.label.toLowerCase().includes(search.toLowerCase()),
            )
          }
          defaultOptions={options}
          emptyStateMessage=''
        />
      </PopoverContent>
    </Popover>
  );
};
