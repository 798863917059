'use client';
import React from 'react';
import {
  FileCard,
  FileCardAuthor,
  FileCardContent,
  FileCardFilename,
  FileCardFooter,
  FileCardHeader,
  FileCardIcon,
  FileCardList,
  FileCardPreview,
  FileCardTag,
  FileCardTagList,
} from './FileCard';
import { ListAppraisalInvoicesResponseBody } from 'bff';
import { FilePreviewList } from './FilePreviewList';

export const AppraisalInvoicesList = ({
  data,
}: {
  data: ListAppraisalInvoicesResponseBody;
}) => {
  return <FilePreviewList files={data.invoices} />;
};
