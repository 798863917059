'use client';

import { Permission } from 'database';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Tabs, TabsList, TabsListProvider, TabsTrigger, cn } from 'ui';
import { PiramidLogo } from './PiramidLogo';
import { UserOptions } from './UserOptions';

export const DashboardLayoutContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('p-14 flex items-center justify-center', className)}>
      <div className='max-w-7xl w-full'>{children}</div>
    </div>
  );
};

export const DashboardLayoutNav = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <nav
      className={cn(
        'h-16 border-b-2 justify-between items-center flex px-10',
        className,
      )}
    >
      {children}
    </nav>
  );
};

export interface LayoutTabItem {
  withoutClientSideRouting?: boolean;
  label: string;
  href: string;
  show: (permissions: Permission[]) => boolean;
}

export const DashboardLayout = ({
  children,
  tabs,
  logout,
}: {
  children?: React.ReactNode;
  tabs: LayoutTabItem[];
  logout: () => Promise<any>;
}) => {
  const pathname = usePathname();

  if (pathname.includes('create')) return <>{children}</>;

  return (
    <main>
      <DashboardLayoutNav>
        <PiramidLogo size={'md'} />
        <TabsListProvider variant={'underline'}>
          <Tabs value={pathname} className='w-[400px] h-full'>
            <TabsList className='h-full'>
              {tabs.map(({ href, label, withoutClientSideRouting }) => {
                return (
                  <TabsTrigger
                    asChild
                    className='h-full'
                    key={href}
                    value={href}
                  >
                    {!withoutClientSideRouting ? (
                      <Link href={href}>{label}</Link>
                    ) : (
                      <a href={href}>{label}</a>
                    )}
                  </TabsTrigger>
                );
              })}
            </TabsList>
          </Tabs>
        </TabsListProvider>
        <UserOptions logout={logout} />
      </DashboardLayoutNav>
      <DashboardLayoutContent>{children}</DashboardLayoutContent>
    </main>
  );
};
