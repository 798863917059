import { IconEdit } from '@tabler/icons-react';
import {
  FetchInsurerClaimDetailResponseBody,
  UpdateClaimRequestBody,
} from 'bff';
import { useState, useCallback } from 'react';
import { useToast, Button } from 'ui';
import {
  ClaimUpdateSegment,
  useSafeClaimUpdate,
} from '../contexts/ClaimUpdateContext';

export const EditClaimButton = ({
  segment,
  data,
}: {
  segment: ClaimUpdateSegment;
  data: FetchInsurerClaimDetailResponseBody;
}) => {
  const update = useSafeClaimUpdate();

  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const handleSave = useCallback((data: UpdateClaimRequestBody) => {
    setIsLoading(true);
    update.onSave!(data)
      .then(() => {
        if (update.onInvalidate) {
          update.onInvalidate();
        }

        toast({
          title: 'Reclamo actualizado',
          description: 'Los cambios del reclamo fueron guardados exitosamente',
        });

        update.setEditingSegment!(undefined);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (update.editingSegment === segment)
    return (
      <div className='flex flex-row items-center space-x-2'>
        <Button
          onClick={() => {
            update.form!.reset();
            update.setEditingSegment!(undefined);
          }}
          disabled={isLoading}
          variant={'outline'}
          size='sm'
        >
          Cancelar
        </Button>
        <Button
          loading={isLoading}
          onClick={() => {
            update.form!.trigger(segment).then((isValid) => {
              if (isValid) {
                handleSave({
                  [segment]: update.form!.getValues(segment),
                });
              }

              if (!isValid) {
                console.log(
                  'form is not valid',
                  update.form!.formState.errors,
                  'values',
                  update.form!.getValues(),
                  'update.form.formState.errors',
                  update.form.formState.errors,
                );
              }
            });
          }}
          size='sm'
        >
          Guardar
        </Button>
      </div>
    );

  return (
    <Button
      size='sm'
      variant='ghost'
      onClick={() => {
        update.form!.reset(update.data);
        update.setEditingSegment!(segment);
      }}
    >
      <IconEdit className='w-4 h-4' />
    </Button>
  );
};
