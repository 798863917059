import { AuthLink, AuthLinkProps } from './AuthLink';

export const LoginLink = (
  props: Pick<AuthLinkProps, 'onClick' | 'asButton'>,
) => {
  return (
    <AuthLink
      path='/login'
      destination='Inicia sesión'
      question='¿Ya tienes una cuenta?'
      {...props}
    />
  );
};
