import { FileWithURL } from 'bff';
import { format } from 'date-fns';
import React from 'react';
import { FileListItem, FileListLabel } from './File';

export const ResourceUpdateCard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='space-y-4 flex flex-col'>{children}</div>;
};

export const ResourceUpdateCardFile = ({
  file,
  onClick,
}: {
  file: FileWithURL;
  onClick?: () => void;
}) => {
  return (
    <a
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className='group cursor-pointer'
      target='_blank'
      key={file.id}
      href={file.url}
    >
      <FileListItem className='group-hover:underline'>
        <FileListLabel mimetype={file.mimetype}>
          {file.original_name}
        </FileListLabel>
      </FileListItem>
    </a>
  );
};

export const ResourceUpdateCardMessage = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <p className='italic text-sm'>{children}</p>;
};

export const ResourceUpdateCardAuthor = ({
  date,
  email,
}: {
  date: Date;
  email: string;
}) => {
  return (
    <span className='text-muted-foreground text-xs'>
      Publicado el {format(new Date(date), 'dd/MM/yyyy HH:mm')} por {email}
    </span>
  );
};
