import { useTranslations } from 'i11n';
import { FlattenDamageType } from 'piramid-constants';

export const useMakeClaimDamages = () => {
  const t = useTranslations();

  const makeFlattenDamageOptions = (
    options: ReadonlyArray<FlattenDamageType> | FlattenDamageType[] = [],
  ): { label: string; value: string }[] => {
    return options.map((damage) => {
      return {
        label: t(`damages.${damage}`),
        value: damage,
      };
    });
  };

  return {
    makeFlattenDamageOptions,
  };
};
