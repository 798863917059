import { AuthLink, AuthLinkProps } from './AuthLink';

export const RegisterLink = (
  props: Pick<AuthLinkProps, 'onClick' | 'asButton'>,
) => {
  return (
    <AuthLink
      path='/register'
      destination='Regístrate'
      question='¿No tienes una cuenta?'
      {...props}
    />
  );
};
