export const EmptyStateText = ({ children }: { children: React.ReactNode }) => {
  return <p className='text-sm'>{children}</p>;
};

export const FileDetailTab = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-col space-y-2'>{children}</div>;
};

export const FileDetailTabTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <h2 className='font-bold text-lg'>{children}</h2>;
};
