import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginLink } from './LoginLink';

export const ForgotPassword = ({
  onSubmit,
}: {
  onSubmit: (data: { email: string }) => Promise<void>;
}) => {
  return (
    <>
      <ForgotPasswordForm
        requestLinkForgotPassword={(data) => onSubmit(data)}
      />
      <LoginLink />
    </>
  );
};
