'use client';
import { CreateEventRequestParams, EventRequestBody } from 'bff';
import { useCallback, useEffect } from 'react';

export type HttpClient = {
  query: (params: object) => HttpClient;
  post: <T extends object>(
    body: T,
    path: string,
  ) => {
    json: () => Promise<any>;
    headers: (headers: object) => HttpClient;
  };
};

export const useEvent = <T extends EventRequestBody['type']>(
  httpClient: HttpClient,
  initEvents: {
    type: T;
    metadata: Extract<EventRequestBody, { type: T }>['metadata'];
  }[] = [],
  suffix?: string,
  token?: string,
) => {
  useEffect(() => {
    if (initEvents.length) {
      const eventWithEntrypoint = initEvents.find(
        (e) => e.metadata && 'entrypoint_endpoint' in e.metadata,
      );

      console.log('eventWithEntrypoint', eventWithEntrypoint);

      httpClient
        .query(
          eventWithEntrypoint
            ? ({
                endpoint: (
                  eventWithEntrypoint as Extract<
                    EventRequestBody,
                    { type: 'claim_form_navigation' }
                  >
                ).metadata.entrypoint_endpoint,
              } satisfies CreateEventRequestParams)
            : {},
        )
        .headers(
          token
            ? {
                authorization: token,
              }
            : {},
        )
        .post(
          {
            events: initEvents,
          },
          `/v1/event/batch${suffix || ''}`,
        )
        .json()
        .catch(console.error);
    }
  }, []);

  const registerEvent = useCallback(
    <T extends EventRequestBody['type']>(
      event: T,
      metadata: Extract<EventRequestBody, { type: T }>['metadata'],
    ) =>
      httpClient
        .query(
          metadata && 'entrypoint_endpoint' in metadata
            ? ({
                endpoint: metadata.entrypoint_endpoint,
              } satisfies CreateEventRequestParams)
            : {},
        )
        .headers(
          token
            ? {
                authorization: token,
              }
            : {},
        )
        .post(
          {
            type: event,
            metadata,
          },
          `/v1/event${suffix || ''}`,
        )
        .json()
        .catch(console.error),
    [],
  );

  return {
    registerEvent,
  };
};
