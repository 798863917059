'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { RegisterUserRequestBody, registerUserRequestBody } from 'bff';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
} from 'ui';
import { SignInIDPFn, SignInWithGoogle } from './SignInWithGoogle';
import { useApiError } from '../hooks/useApiError';
import { SignInWithMicrosoft } from './SignInWithMicrosoft';

export type FormData = RegisterUserRequestBody;

export type RegisterUserFormData = FormData;

export const RegisterUserForm = ({
  registerUser,
  signInIDP,
  defaultValues,
  withGoogle = true,
  withMicrosoft = false,
  withIDP = true,
}: {
  registerUser: (data: FormData) => Promise<any>;
  signInIDP?: SignInIDPFn;
  defaultValues?: Partial<RegisterUserRequestBody>;
  withGoogle?: boolean;
  withMicrosoft?: boolean;
  withIDP?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    registerUser(data)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  console.log('default value', defaultValues);

  const form = useForm<FormData>({
    resolver: zodResolver(registerUserRequestBody),
    defaultValues,
  });

  return (
    <div className='w-full'>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className='w-full grid grid-cols-2 gap-4'
        >
          <FormField
            control={form.control}
            name='first_name'
            defaultValue=''
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Nombre</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name='last_name'
            defaultValue=''
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Apellido</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name='email'
            defaultValue=''
            render={({ field }) => {
              return (
                <FormItem className='col-span-2'>
                  <FormLabel>Ingresa un email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder='ejemplo@gmail.com'
                      disabled={true}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name='password'
            defaultValue=''
            render={({ field }) => {
              return (
                <FormItem className='col-span-2'>
                  <FormLabel>Elige una contraseña</FormLabel>
                  <FormControl>
                    <Input type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <Button loading={isLoading} type='submit' className='col-span-2 mt-5'>
            Crear cuenta
          </Button>
        </form>
      </Form>
      {withIDP && (
        <>
          <SeparatorText>O también podes</SeparatorText>
          <div className='space-y-2'>
            {withGoogle && <SignInWithGoogle signInIDP={signInIDP!} />}
            {withMicrosoft && <SignInWithMicrosoft signInIDP={signInIDP!} />}
          </div>
        </>
      )}
    </div>
  );
};
