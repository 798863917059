'use client';

import { IconRun, IconSettings } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'ui';
import { UserAvatar } from './UserAvatar';
import { useApiError } from '../hooks/useApiError';
import { useSharedAuth } from '../contexts/SharedAuthContext';

const Logout = ({ logout }: { logout: () => Promise<any> }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const onLogout = useCallback(() => {
    setIsLoading(true);
    logout()
      .then(() => {
        window.location.href = '/login';
      })
      .catch(handleError);
  }, []);

  return (
    <DropdownMenuItem onClick={onLogout}>
      <IconRun className='w-4 h-4 mr-2' />
      Cerrar sesión
    </DropdownMenuItem>
  );
};

export const UserOptions = ({ logout }: { logout: () => Promise<any> }) => {
  const { user } = useSharedAuth();

  const router = useRouter();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <UserAvatar data={user} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          {user!.first_name} {user!.last_name}
          <br />
          <span className='text-zinc-500 font-light'>{user!.email}</span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <a href='/settings/account'>
          <DropdownMenuItem>
            <IconSettings className='w-4 h-4 mr-2' />
            Configuración
          </DropdownMenuItem>
        </a>

        <DropdownMenuSeparator />
        <Logout logout={logout} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
