import { Separator, cn } from 'ui';

export const Settings = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={className}>{children}</div>;
};

const Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={cn('space-y-1', className)}>{children}</div>;
};

const Title = ({ children }: { children: React.ReactNode }) => {
  return <h3 className='text-lg font-medium'>{children}</h3>;
};

const Description = ({ children }: { children: React.ReactNode }) => {
  return <p className='text-sm text-muted-foreground'>{children}</p>;
};

const SettingsSeparator = () => {
  return <Separator className='my-6' />;
};

Settings.Header = Header;
Settings.Title = Title;
Settings.Description = Description;
Settings.Separator = SettingsSeparator;
