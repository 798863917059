'use client';
import React from 'react';

export interface IntegrationBindingsContext {
  onIntegrationDisconnected: () => void;
  onIntegrationConnected: () => void;
  disconnectIntegration: (params: { id: number }) => Promise<any>;
}

const IntegrationBindingsContext = React.createContext<
  IntegrationBindingsContext | undefined
>(undefined);

export const useIntegrationBindings = () =>
  React.useContext(IntegrationBindingsContext)!;

export const IntegrationBindingsProvider = ({
  children,
  ...bindings
}: {
  children: React.ReactNode;
} & IntegrationBindingsContext) => {
  return (
    <IntegrationBindingsContext.Provider value={bindings}>
      {children}
    </IntegrationBindingsContext.Provider>
  );
};
