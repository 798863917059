'use client';
import { useTranslations } from 'i11n';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { Tabs, TabsList, TabsTrigger } from 'ui';

export const TabsSwitcher = ({
  tabs,
  prefix,
}: {
  prefix: string;
  tabs: {
    path: string;
    label: string;
  }[];
}) => {
  const path = usePathname();

  const t = useTranslations();

  const tab = useMemo(() => {
    return Array.from(tabs)
      .sort((a) => (!!a.path ? -1 : 1))
      .find((tab) => path.includes(tab.path))?.path;
  }, [path]);

  return (
    <Tabs value={tab}>
      <TabsList>
        {tabs.map((tab, index) => {
          return (
            <Link key={tab.path} href={`${prefix}/${tab.path}`}>
              <TabsTrigger key={index} value={tab.path}>
                {tab.label}
              </TabsTrigger>
            </Link>
          );
        })}
      </TabsList>
    </Tabs>
  );
};
