'use client';

import { SignInRequestBody } from 'bff';
import { useCallback, useState } from 'react';
import { useApiError } from '../hooks/useApiError';
import { GoogleBtn } from './GoogleSignInBtn';

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export const SignInWithGoogle = ({
  signInIDP,
  onDone,
}: {
  signInIDP: SignInIDPFn;
  onDone?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const handleSignIn = (accessToken: string) => {
    setIsLoading(true);

    signInIDP({
      identity: {
        access_token: accessToken,
        provider: 'google',
      },
    })
      .then(() => {
        if (onDone) {
          onDone();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  return <GoogleBtn isLoading={isLoading} onAccept={handleSignIn} />;
};
