import { useCallback, useMemo, useState } from 'react';
import pick from 'lodash.pick';

export const useFiltersVisibility = <
  TFilterKey extends string,
  TFilterValue extends object,
>(
  {
    filterValue,
    defaultValue = [],
    filterList,
    propsEquivalence,
    propsDefaultValues,
  }: {
    filterValue: TFilterValue;
    filterList: TFilterKey[];
    defaultValue: TFilterKey[];
    propsEquivalence: Record<TFilterKey, string[]>;
    propsDefaultValues: Record<TFilterKey, any>;
  },
  onUpdateFilters: (filters: TFilterValue) => void,
) => {
  const [filters, setFilters] = useState<TFilterKey[]>(defaultValue);

  const onAddFilter = useCallback(
    (filter: TFilterKey) => setFilters((prev) => prev.concat(filter)),
    [],
  );

  const onRemoveFilter = useCallback(
    (indexToRemove: number) =>
      setFilters((prev) => prev.filter((_, index) => indexToRemove !== index)),
    [],
  );

  const filtersAvailable = useMemo(
    () =>
      filterList.filter(
        (filterList) =>
          !filters.find((filterSaved) => filterSaved === filterList),
      ),
    [filters],
  );

  const _setFilters = useCallback(
    (filters: TFilterKey[]) => {
      const whitelistProps = filters.flatMap((f) => propsEquivalence[f]);

      const cleanFilters = pick(filterValue, whitelistProps);

      setFilters(filters);

      const cleanFiltersDefaultValue = whitelistProps.reduce((acc, curr) => {
        if (curr in acc) return acc;

        return {
          ...acc,
          [curr]: propsDefaultValues[curr as keyof typeof propsDefaultValues],
        };
      }, cleanFilters);

      onUpdateFilters(cleanFiltersDefaultValue as TFilterValue);
    },
    [filterValue],
  );

  return {
    setFilters: _setFilters,
    filters,
    getControllerProps: () => {
      return {
        setFilters: _setFilters,
        filters,
        onAddFilter,
        onRemoveFilter,
        filterList,
        filtersAvailable,
      };
    },
  };
};

export type FiltersVisibilityControllerProps<
  TFilterKey extends string,
  TFilterValue extends object,
> = ReturnType<
  ReturnType<
    typeof useFiltersVisibility<TFilterKey, TFilterValue>
  >['getControllerProps']
>;
