import { ColumnDef } from '@tanstack/react-table';
import { OrderRelationTypeMetadata } from 'bff';
import { OrderItem } from 'database';
import { useMemo } from 'react';
import { AmountDisplay } from './AmountDisplay';
import { DataTable } from 'ui';

export interface ProvidedOrderItem extends OrderItem {
  affected_coverage_name?: string;
}

export const OrderDetailsDataTable = ({
  items,
  additionalColumns = [],
  relationType,
}: {
  items: ProvidedOrderItem[];
  additionalColumns?: ColumnDef<ProvidedOrderItem>[];
  relationType: OrderRelationTypeMetadata['relationType'];
}) => {
  const columns = useMemo((): ColumnDef<ProvidedOrderItem>[] => {
    return [
      {
        id: 'affected_coverage',
        header: 'Cobertura',
        accessorFn: (row) => row.affected_coverage_name,
      },
      {
        header: 'Descripción',
        accessorKey: 'quantity_description',
      },
      {
        header: 'Cantidad',
        accessorKey: 'quantity_value',
      },
      {
        header: 'Monto',
        cell: (item) => <AmountDisplay item={item.row.original} />,
      },
      {
        header: 'Impuestos',
        accessorFn: (row) => {
          if (!row.taxes_applied) return 'No';

          return `${row.tax_percentage}%`;
        },
      },
      ...additionalColumns,
    ];
  }, []);

  return (
    <DataTable
      initialState={{
        columnVisibility: {
          affected_coverage: relationType === 'complaint',
        },
      }}
      columns={columns}
      data={items}
    />
  );
};
