'use client';
import { useQuery } from '@tanstack/react-query';
import { ListFilesRequestQuery, ListFilesResponseBody } from 'bff';
import { FileTypes } from 'database';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import debounce from 'lodash.debounce';
import React, { useCallback, useState } from 'react';
import {
  FileCard,
  FileCardAuthor,
  FileCardContent,
  FileCardFilename,
  FileCardFooter,
  FileCardHeader,
  FileCardIcon,
  FileCardList,
  FileCardPreview,
  FileCardTag,
  FileCardTagList,
} from 'shared-components';
import {
  ComboboxOption,
  Input,
  Separator,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Tabs,
  TabsContent,
  TabsList,
  TabsListProvider,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'ui';
import { getHumanFileSize } from 'utils';
import { DocumentTagsFilter, SearchTagsFn } from './DocumentTagsFilter';
import { DocumentTypesFilter } from './DocumentTypesFilter';
import { EntityValidations } from './EntityValidations';
import { InferredEntities } from './InferredEntityCard';
import { IconCheck, IconCircleCheckFilled } from '@tabler/icons-react';

export const buildDocumentationQueryKey = () => `documentation-files`;

export type ListFilesFN = (input: {
  query?: ListFilesRequestQuery;
  cookie?: string;
}) => Promise<ListFilesResponseBody>;

type File = ListFilesResponseBody['files'][number];

export type DocumentsFileExplorerProps = {
  initialData: ListFilesResponseBody;
  searchTags: SearchTagsFn;
  listFiles: ListFilesFN;
  onFilterChange?: (filters: {
    selectedValues: ComboboxOption[];
    selectedTypes: ComboboxOption[];
  }) => void;
  onSearch?: (search: string) => void;
  onOpenFile?: (file: File) => void;
} & Pick<ListFilesRequestQuery, 'scope_id' | 'scope_type'>;

export const DocumentsFileExplorer = ({
  initialData,
  scope_id,
  scope_type,
  searchTags,
  listFiles,
  onFilterChange,
  onSearch,
  onOpenFile,
}: DocumentsFileExplorerProps) => {
  const [selectedValues, setSelectedValues] = useState<ComboboxOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<ComboboxOption[]>([]);

  const [globalFilter, setGlobalFilter] = useState('');

  const { data } = useQuery({
    queryKey: [
      buildDocumentationQueryKey(),
      selectedValues,
      selectedTypes,
      globalFilter,
    ] as const,
    queryFn: (ctx) => {
      const [_, selectedValues, selectedTypes, globalFilter] = ctx.queryKey;

      return listFiles({
        query: {
          scope_id,
          scope_type,
          search: globalFilter,
          types: selectedTypes.map((v) => v.value as FileTypes),
          tags: selectedValues.map((v) => Number(v.value)),
        },
      });
    },
    initialData,
  });

  const t = useTranslations();

  const debouncedGlobalFilter = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(ev.target.value);

      if (onSearch) {
        onSearch(ev.target.value);
      }
    }, 500),
    [],
  );

  return (
    <div className='flex flex-col space-y-6'>
      <div className='flex flex-row items-center space-x-2'>
        <Input onChange={debouncedGlobalFilter} placeholder='Buscar' />
        <DocumentTypesFilter
          selectedValues={selectedTypes}
          setSelectedValues={(types) => {
            setSelectedTypes(types);

            if (onFilterChange) {
              onFilterChange({
                selectedTypes: types,
                selectedValues,
              });
            }
          }}
        />
        <DocumentTagsFilter
          searchTags={searchTags}
          setSelectedValues={(values) => {
            setSelectedValues(values);

            if (onFilterChange) {
              onFilterChange({
                selectedTypes,
                selectedValues: values,
              });
            }
          }}
          selectedValues={selectedValues}
        />
      </div>
      <FileCardList>
        {data.files.map((f) => {
          return (
            <TooltipProvider key={f.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Sheet
                    onOpenChange={(open) => {
                      if (open && onOpenFile) {
                        onOpenFile(f);
                      }
                    }}
                  >
                    <SheetTrigger asChild>
                      <FileCard className='relative'>
                        {f.external_synced_at && (
                          <div className='mt-0 absolute bottom-0 right-0 p-2'>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <IconCircleCheckFilled className='w-6 h-6 text-primary' />
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>
                                    {f.external_id}
                                    <br />
                                    Archivo sincronizado a las{' '}
                                    {format(
                                      new Date(f.external_synced_at),
                                      'dd/MM/yyyy HH:mm',
                                    )}{' '}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        )}
                        <FileCardHeader>
                          <FileCardIcon mimetype={f.mimetype!} />
                          <FileCardFilename>{f.original_name}</FileCardFilename>
                        </FileCardHeader>
                        <FileCardContent>
                          <FileCardPreview
                            type={f.type}
                            previewURL={f.previewURL}
                          />
                        </FileCardContent>
                        <FileCardFooter>
                          <FileCardTagList>
                            {f.tags.map((tag) => (
                              <FileCardTag>{tag.name}</FileCardTag>
                            ))}
                          </FileCardTagList>
                          <FileCardAuthor
                            createdAt={f.created_at}
                            uploadedByEmail={f.uploaded_by?.email!}
                          />
                          <div className='w-full flex flex-col'>
                            <div>
                              <p>
                                <span className='font-bold'>
                                  {f._count.entities}
                                </span>{' '}
                                {f._count.entities > 1
                                  ? 'entidades'
                                  : f._count.entities === 0
                                  ? 'entidades'
                                  : 'entidad'}{' '}
                              </p>
                            </div>

                            <div>
                              <p>
                                <span className='font-bold'>
                                  {f._count.validations}
                                </span>{' '}
                                {f._count.validations > 1
                                  ? 'validaciones'
                                  : f._count.validations === 0
                                  ? 'validaciones'
                                  : 'validación'}{' '}
                              </p>
                            </div>
                          </div>
                        </FileCardFooter>
                      </FileCard>
                    </SheetTrigger>
                    <SheetContent>
                      <Tabs defaultValue='detail' className='w-full'>
                        <SheetHeader>
                          <TabsListProvider variant={'underline'}>
                            <TabsList className='flex items-center justify-center'>
                              <TabsTrigger value='detail'>Detalle</TabsTrigger>
                              <TabsTrigger value='entities'>
                                Entidades
                              </TabsTrigger>
                              <TabsTrigger value='validations'>
                                Validaciones
                              </TabsTrigger>
                            </TabsList>
                          </TabsListProvider>
                        </SheetHeader>

                        <TabsContent
                          className='flex flex-col space-y-4'
                          value='detail'
                        >
                          <div className='flex flex-row items-center space-x-1'>
                            <FileCardIcon mimetype={f.mimetype!} />
                            <SheetTitle className='text-lg'>
                              {f.original_name}
                            </SheetTitle>
                          </div>
                          <a
                            onClick={() => {
                              if (onOpenFile) {
                                onOpenFile(f);
                              }
                            }}
                            target='_blank'
                            href={f.url}
                          >
                            <FileCardPreview
                              previewURL={f.previewURL}
                              type={f.type}
                            />
                          </a>
                          <Separator className='my-2' />
                          <div className='flex flex-col space-y-4'>
                            <h2 className='font-bold text-lg'>
                              Detalles del archivo
                            </h2>
                            <ul className='text-sm flex flex-col space-y-2'>
                              <li>
                                <span className='font-bold'>Tipo</span>
                                <br />
                                <span>{t(`file_types.${f.type}`)}</span>
                              </li>
                              <li>
                                <span className='font-bold'>Peso</span>
                                <br />
                                <span>{getHumanFileSize(f.size)}</span>
                              </li>
                              {f.original_uploaded_at && (
                                <li>
                                  <span className='font-bold'>
                                    Fecha de subida
                                  </span>
                                  <br />
                                  <span>
                                    {format(
                                      new Date(f.original_uploaded_at),
                                      'Pp',
                                    )}
                                  </span>
                                </li>
                              )}

                              {f.uploaded_by && (
                                <li>
                                  <span className='font-bold'>Subido por</span>
                                  <br />
                                  <span>
                                    {f.uploaded_by.first_name}{' '}
                                    {f.uploaded_by.last_name}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </TabsContent>
                        <TabsContent value='entities'>
                          <InferredEntities entities={f.entities} />
                        </TabsContent>
                        <TabsContent value='validations'>
                          <EntityValidations validations={f.validations} />
                        </TabsContent>
                      </Tabs>
                    </SheetContent>
                  </Sheet>
                </TooltipTrigger>
                <TooltipContent className='flex flex-row items-center space-x-2'>
                  <p>{f.stored_name}</p>
                  <span className='text-muted-foreground'>
                    {getHumanFileSize(Number(f.size))}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        })}
      </FileCardList>
    </div>
  );
};
