import { IconCheck } from '@tabler/icons-react';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import {
  ComboboxOption,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInputForm,
  CommandItem,
  CommandList,
  ScrollArea,
} from 'ui';

export const ComboboxAsyncList = <TOption extends ComboboxOption>({
  onValueChange,
  searchOptions,
  value = [],
  placeholder,
}: {
  onValueChange: (options: ComboboxOption[]) => void;
  searchOptions: (search: string) => Promise<TOption[]>;
  value: ComboboxOption[];
  placeholder?: string;
}) => {
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);

  const [optsFound, setOptsFound] = useState<TOption[]>([]);

  const onInputChange = useCallback((search: string) => {
    searchOptions(search)
      .then(setOptsFound)
      .finally(() => setLoading(false));
  }, []);

  const debouncedInputChange = useCallback(debounce(onInputChange, 500), []);

  const optionsList = !search
    ? value.length > 0
      ? value
      : optsFound
    : optsFound;

  return (
    <Command className='overflow-hidden rounded-t-none'>
      <CommandInputForm
        loading={loading}
        onValueChange={(search) => {
          setSearch(search);
          setLoading(true);
          debouncedInputChange(search);
        }}
        placeholder={placeholder}
      />
      <CommandList withScroll={false}>
        {!!search && (
          <CommandEmpty>
            {loading ? 'Buscando...' : 'No se encontraron resultados'}
          </CommandEmpty>
        )}

        <CommandGroup className='mt-2 !px-0'>
          {optionsList.map((opt) => (
            <CommandItem
              key={opt.value}
              className='flex items-center'
              value={opt.value.toString()}
              onSelect={() => {
                if (value.some((selected) => selected.value === opt.value)) {
                  return onValueChange(
                    value.filter(
                      (selected) =>
                        selected.value.toString() !== opt.value.toString(),
                    ),
                  );
                }

                return onValueChange(value.concat(opt));
              }}
            >
              {opt.label}
              {value.some((selected) => selected.value === opt.value) && (
                <IconCheck className='ml-auto flex h-5 w-5 text-primary' />
              )}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
