'use client';

import { FetchClaimDetailResponseBody } from 'bff';
import { createContext, useContext } from 'react';

export interface SharedClaimContext {
  claim: FetchClaimDetailResponseBody;
}

const SharedClaimContext = createContext<SharedClaimContext | undefined>(
  undefined,
);

export const useSharedClaim = () => useContext(SharedClaimContext)!;

export const SharedClaimProvider = ({
  claim,
  children,
}: {
  claim: FetchClaimDetailResponseBody;
  children: React.ReactNode;
}) => {
  return (
    <SharedClaimContext.Provider
      value={{
        claim,
      }}
    >
      {children}
    </SharedClaimContext.Provider>
  );
};
