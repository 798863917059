import React, { forwardRef, useContext } from 'react';
import { cn } from 'ui';
import { ComponentSize } from './ChoiceOptions';
import { IconCheck } from '@tabler/icons-react';

const alphabet = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
};

export type ChoiceOptionValue = string | number | boolean;

interface ChoiceOptionsContext {
  value?: ChoiceOptionValue;
}

export const ChoiceOptionsContext = React.createContext<
  ChoiceOptionsContext | undefined
>(undefined);

export const useChoiceOptions = () => useContext(ChoiceOptionsContext);

export const ChoiceOptionsProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: ChoiceOptionValue;
}) => {
  return (
    <ChoiceOptionsContext.Provider value={{ value }}>
      {children}
    </ChoiceOptionsContext.Provider>
  );
};

export const ChoiceOptionSelectedMarker = forwardRef<
  HTMLDivElement,
  Omit<React.HTMLProps<HTMLDivElement>, 'value' | 'size'> & {
    selected?: boolean;
    size?: ComponentSize;
  }
>(({ className, selected, size, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'absolute transition-all h-full flex items-center justify-center top-0 right-3 opacity-0',
        {
          'opacity-100': selected,
        },
        className,
      )}
      {...props}
    >
      <IconCheck
        className={cn('text-primary', {
          'w-4 h-4': size === 'xs',
        })}
      />
    </div>
  );
});

export const ChoiceOptionContainer = forwardRef<
  HTMLDivElement,
  Omit<React.HTMLProps<HTMLDivElement>, 'value' | 'size'> & {
    selected?: boolean;
    size?: ComponentSize;
  }
>(({ className, selected, size, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'bg-slate-200 relative  cursor-pointer border-2 border-primary/40 p-3 rounded-md w-full items-start justify-between flex flex-col transition-all group hover:bg-slate-200/50',
        className,
        {
          'border-primary': selected,
          'p-1': size === 'xs',
        },
      )}
      {...props}
    />
  );
});

export const ChoiceOption = ({
  selected,
  index,
  children,
  className,
  size,
}: {
  selected?: boolean;
  index: number;
  children: React.ReactNode;
  className?: string;
  size?: ComponentSize;
}) => {
  return (
    <div
      className={cn(
        'text-primary flex flex-row items-start sm:items-center space-x-2 text-md',
        className,
        {
          'text-xs': size === 'xs',
        },
      )}
    >
      <div
        className={cn(
          'h-8 w-8 rounded-md bg-white  border-primary/40 border flex items-center justify-center transition-all flex-shrink-0',
          {
            'text-white text-primary border-primary': selected,
            'h-6 w-6': size === 'xs',
          },
        )}
      >
        <span>{alphabet[index as keyof typeof alphabet]}</span>
      </div>
      <p className='text-md overflow-hidden text-ellipsis'>{children}</p>
    </div>
  );
};
