import React from 'react';
import { PiramidLogo } from 'ui';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='h-screen w-full flex flex-row overflow-hidden'>
      <div className='w-1/2 h-full bg-primary p-6'>
        <div className='m-2 h-full'>
          <div className='w-8 h-8'>
            <PiramidLogo variant='white' />
          </div>
          <div className='absolute inset-x-0 bottom-0 text-white m-8 w-2/5'>
            {`"Piramid es una plataforma de seguros que te permite gestionar tus siniestros de forma fácil y rápida"`}
          </div>
        </div>
        <div></div>
      </div>
      <div className='w-1/2 h-full p-6 flex items-center justify-center'>
        <div className='max-w-4xl w-full h-full'>
          <div className='h-full flex items-center justify-between flex-col'>
            <div
              style={
                {
                  '--radius': `${0.5}rem`,
                } as React.CSSProperties
              }
              className='w-18 md:w-96 space-y-4 justify-items-center mt-56'
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
